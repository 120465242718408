<template>
	<footer class="footer" v-background="backgroundOverlay">
		<base-container>
			<div class="wrapper">
				<div class="footer-logo-wrapper" v-animate="`opacity`">
					<div class="logo-wrapper">
						<base-svg class="footer-logo" name="logo_color"/>
						<base-link :to="euData.eu_alias" title="Europejski Fundusz Rozwoju Regionalnego">
							<cms-image
								class="eu-project"
								lazy
								:src="euData.eu_logo"
								:base="{width: 180}"
								:lg="{width: 150, height: 52}"
							/>
						</base-link>
					</div>
                    <div class="footer-content">
                        <base-font weight="bold"  color="primary">
                            {{footerData.owner}}
                        </base-font>
                        <base-font weight="bold" color="primary">
                            {{footerData.postalCode}} {{footerData.addressLocality}}, {{footerData.streetAddress}}
                        </base-font>
                        <base-font color="primary">
                            {{footerLangs.nip}}: {{footerData.nip}}
                        </base-font>
                        <base-link type="primary" :url="`tel:${footerData.phoneNumber}`" title="Skontaktuj się z nami">
                            {{footerLangs.tel}}:
                            {{footerData.phoneNumber}}
                        </base-link>
                        <base-link type="primary" :url="`mailto:${footerData.email}`" title="Email do działu kontakt">
                            {{footerLangs.Email}}:
                            {{footerData.email}}
                        </base-link>
                        <br />
                        <div class="account-numbers">
                            <base-font weight="bold" color="primary" >
                                {{footerLangs.account_numbers}}
                            </base-font>
                            <base-font color="primary" >
                                {{footerData.account_number}}
                            </base-font>
                            <base-font color="primary" >
                                {{footerData.account_number_foreign}}
                            </base-font>
                        </div>
                    </div>
				</div>
				<div class="footer-links ds-grid" v-animate="`opacity`">
					<footer-link
						v-for="item in footerMenu"
						:key="item.name"
						:name="item.name"
						:to="item.to"
					/>
					<footer-link-additional
						v-for="item in additionalMenu"
						:key="item.name"
						:name="item.name"
						:to="item.to"
					/>
				</div>
			</div>
		</base-container>
	</footer>
</template>
<script>
import FooterLink from './components/FooterLink'
import FooterLinkAdditional from './components/FooterLinkAdditional.vue'
export default {
	components: {
		FooterLink,
		FooterLinkAdditional
	},
	async prefetch () {
		await this.$app.translator.prefetch('footer')
	},
	computed: {
		footerData () {
			return this.$app.getSettings().general.contact
		},
		footerLangs () {
			return this.$app.translator.get('footer')
		},
		euData () {
			return this.$app.layout.value
		},
		footerMenu () {
			const menu = this.$app.layout.value.menu.items
			const reducedMenu = menu.reduce((acc, currVal) => {
				if (currVal.children.length) currVal.children.forEach(el => acc.push({ name: el.name, to: el.link }))
				else acc.push({ name: currVal.name, to: currVal.to })
				return acc
			}, [])
			return reducedMenu
		},
		additionalMenu () {
			const additionalLinks = this.$app.layout.value.menu.additionalLinks
			return additionalLinks
		},
		backgroundOverlay () {
			const path = '/products/product-07.png'
			return {
				src: path,
				breakpoints: {
					base: { width: 720, height: 650 },
					lg: { width: 825, height: 451 },
					xl: { width: 1920, height: 515 }
				}
			}
		}
	}
}

</script>
<style lang="scss" scoped>
.footer {
    background-repeat: no-repeat;
    background-size: cover;
	padding: 4rem 0;
	@include media-breakpoint-up(md) {
		padding: 4rem 0;
	}
	@include media-breakpoint-up(xl) {
		padding: 6rem 0 ;
	}
	::v-deep.footer-logo {
		display: block;
		width: 210px;
	}
	&-logo-wrapper {
		flex-shrink: 0;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: center;
            gap: 3rem;
        }
        @include media-breakpoint-up(lg) {
            flex-direction: column;
            gap:0;
            align-items: flex-start;
        }
	}
	&-links {
		columns: 2;
		padding-top: 4rem;
        @include media-breakpoint-up(sm) {
            columns: 3;
        }
		@include media-breakpoint-up(md) {
			columns: 4;
		}
		@include media-breakpoint-up(lg) {
			padding-top: 0;
			margin-left: 5rem;
		}
		@include media-breakpoint-up(xl) {
			margin-left: 10rem;
		}
	}
    &-content {
        display: flex;
        flex-direction: column;
    }
	.logo-wrapper {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 2rem;
        margin-bottom: 2rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            align-items: center;
        }
		@include media-breakpoint-up(lg) {
			flex-direction: row;
            margin-bottom: 2rem;
		}

		svg{
			width: 210px;
		}
	}
}
.wrapper {
	display: flex;
	flex-direction: column;
	@include media-breakpoint-up(lg) {
		flex-direction: row;
	}
}
@include media-breakpoint-up(lg) {
	.ds-grid {
		display: grid;
		grid-template-columns: repeat(3,1fr);
		width: 100%;
	}
}
</style>
