<template>
	<div class="field-wrapper"
		:info="input.info"
	>
		<div class="field-label">
			<base-font
				variant="label"
				color="white"
				:for="input.name"
				@click.stop="$emit('click')"
			>{{input.label}}</base-font>
		</div>
		<div class="field-inner-wrapper">
			<base-mask-input
				class="input"
				:autocomplete="$attrs.autocomplete"
				:input="input"
				:type="input.type"
			/>
			<div
				v-if="input.info"
				class="field-icon input-info"
				v-tooltip="{content: input.info}"
			>
				<base-svg name="info_button"/>
			</div>
		</div>
		<base-error :error="input.error" />
	</div>
</template>

<script>
import mixin from '../utils/input.mixin'
import BaseError from '@f/components/BaseError'

export default {
	mixins: [mixin],
	components: {
		BaseError
	}
}
</script>
<style lang="scss" scoped src="../utils/fields.scss" />
