<template>
	<div class="benefit-wrapper">
		<base-font tag="p" color="primary" class="heading">{{ benefit.tiles_heading }}</base-font>
		<div class="benefit">
			<base-font class="title" tag="h3" color="primary" uppercase size="md">{{ benefit.tiles_title }}</base-font>
			<div class="tiles">
				<base-svg :name="benefit.tiles_icon" class="tiles-icon"/>
				<base-font class="tiles-desc" tag="div" color="primary" uppercase :html="benefit.tiles_desc"/>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		benefit: Object
	}
}

</script>
<style lang="scss" scoped>
.benefit-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	::v-deep .heading {
		margin-bottom: 2rem;
		text-align: left;
	}

	@include media-breakpoint-up(xl) {
		display: grid;
	}
}

.benefit {
	box-shadow: 6px 6px 38px rgb(0 0 0 / 10%);
	background-color: $white;
	padding: 3rem;
	border-radius: .2rem;
	::v-deep .title {
		margin-bottom: 0;
		font-weight: 400;
		line-height: 2.6rem;
		font-size: 1.3rem;

		@include media-breakpoint-up(sm) {
			font-size: 1.6rem;
		}

		@include media-breakpoint-up(xxl) {
			font-size: 2rem;
			padding: 0;
		}
	}
}

.tiles {
	display: flex;
	gap: 0 2rem;

	@include media-breakpoint-up(xl) {
		flex-direction: column;
		justify-items: center;
		align-items: center;
		margin-top: 20px;
		text-align: center;
		gap: 10px;
	}

	@include media-breakpoint-up(xxl) {
		margin-top: 10px;
		flex-direction: row;
		text-align: left;
		gap: 0 2rem;
	}

	&-icon {
		max-width: 6.4rem;
		min-width: 3rem;
	}

	::v-deep &-desc {

		p {
			font-size: 1.3rem;
			letter-spacing: 0.08em;
			line-height: 2.6rem;

			strong{
				line-height: 26px;
				margin: 6px 0;
			}

			@include media-breakpoint-up(sm) {
				font-size: 1.6rem;
			}

			@include media-breakpoint-up(xxl) {
				font-size:2rem;
				line-height: 5rem;
			}
		}
		strong {
			display: block;
			font-size: 2.2rem;
			line-height: 2.6rem;
			margin-bottom: .6rem;
			letter-spacing: 0.04em;

			@include media-breakpoint-up(xl) {
				font-size: 3rem!important;
			}

			@include media-breakpoint-up(xxl) {
				font-size: 4rem!important;
			}
		}
	}
}
</style>
