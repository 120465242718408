<template>
	<section class="newsletter" v-background="background">
		<base-container>
			<cms-text class="newsletter-title" :props="{variant: 'small-heading', tag: 'h2'}" value="newsletter.title"
				layout v-animate="`opacity`" />
			<div class="newsletter-text-wrapper" v-animate="`opacity`">
				<cms-text value="newsletter.under_title_text" layout />
			</div>
			<base-blur :blur="!!status">
				<form class="form" v-if="form">
					<div class="form-inner-wrapper" v-animate="`opacity`">
						<input-template class="form-input input-name" :input="form.inputs.nameNewsletter" />
						<input-template class="form-input input-email" :input="form.inputs.emailNewsletter"
							autocomplete="email" />
						<input-template class="input-checkbox" :input="form.inputs.privacyNewsletter" />

						<button-submit class="newsletter-submit" @click="submit" :loading="loading" type="secondary"
							icon="phone" v-animate="`opacity`">
							<cms-text :props="{clear: true}" value="newsletter.btn_text" layout />
						</button-submit>
					</div>
				</form>
				<template #status>
					<div class="status">
						<base-font class="status-text" weight="bold" size="md" color="primary">
							{{statusTranslation[status] || statusTranslation.error}}
						</base-font>
					</div>
				</template>
			</base-blur>
		</base-container>
	</section>
</template>

<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'

import {
	Form
} from '@f/core/forms/Form'
import {
	InputTemplate,
	InputText,
	InputEmail,
	InputCheckbox
} from '@f/core/forms/inputs'
import {
	required,
	email
} from 'utils/inputs/validation-front'

export default {
	data () {
		return {
			status: false,
			form: false,
			loading: false
		}
	},
	components: {
		InputTemplate,
		ButtonSubmit
	},
	computed: {
		background () {
			const path = this.$app.layout.value.newsletter.bg_picture.path

			return {
				src: path,
				breakpoints: {
					base: {
						width: 360,
						height: 650
					},
					lg: {
						width: 825,
						height: 451
					}
				}
			}
		},
		statusTranslation () {
			return this.$app.translator.get('forms.newsletter.status')
		}
	},
	async mounted () {
		await this.$app.translator.prefetch('errors')
		await this.$app.translator.prefetch('forms.newsletter')
		this.form = new Form()
		this.form.addInput(InputText.create({
			translations: () => this.$app.translator.get('forms.newsletter.inputName'),
			attribute: 'name',
			name: 'nameNewsletter',
			validation: [required()]
		}))
		this.form.addInput(InputEmail.create({
			translations: () => this.$app.translator.get('forms.newsletter.inputEmail'),
			attribute: 'email',
			name: 'emailNewsletter',
			validation: [email()]
		}))
		this.form.addInput(InputCheckbox.create({
			translations: () => this.$app.translator.get('forms.newsletter.inputPolice'),
			attribute: 'police',
			name: 'privacyNewsletter',
			validation: [required()]
		}))
	},
	methods: {
		async submit () {
			this.status = ''
			if (!await this.form.validate()) return false
			this.loading = true
			const {
				name,
				email
			} = this.form.getValues()
			const status = await this.$app.getService('rext').addSubscriber({
				name,
				email
			})
			if (status === 201) this.$app._emit('newsletter:subscriber:new')
			this.loading = false
			this.status = status
		}
	},
	watch: {
		$route () {
			this.form.hideErrors()
		}
	}
}

</script>
<style lang="scss" scoped>
	.newsletter {
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		overflow: hidden;
		padding: 4rem 0;
		background-position: 100% 50%;
		z-index: 0;

		@include media-breakpoint-up(lg) {
			background-size: contain;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(90deg, rgba(246, 246, 246, 1) 0%, rgba(246, 246, 246, 0.6) 70%, rgba(246, 246, 246, 0.33) 100%);
			z-index: -1;

			@include media-breakpoint-up(md) {
				background: linear-gradient(90deg, rgba(246, 246, 246, 1) 66%, rgba(246, 246, 246, 0.13) 100%);
			}

			@include media-breakpoint-up(lg) {
				background: linear-gradient(90deg, rgba(246, 246, 246, 1) calc(100vw - 650px), rgba(246, 246, 246, 0.13) 100%);
			}

		}

		@include media-breakpoint-up(lg) {
			padding: 6rem 0 4rem;
			width: 100%;
		}

		&-title {
			margin-bottom: 3rem;
		}

		&-text-wrapper {
			max-width: 280px;
			margin-bottom: 4rem;

			@include media-breakpoint-up(md) {
				max-width: none;
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: 3rem;
			}
		}

		.form {
			&-inner-wrapper {
				display: flex;
				flex-direction: column;
				margin-bottom: 2rem;

				.field-wrapper{
					width: 100%;
				}

				& ::v-deep .input:not(:last-child) {
					margin-right: 3rem;
				}

				& ::v-deep label {
					color: $primary;
				}

				& ::v-deep(.field-checkbox-wrapper) {
					@include media-breakpoint-up(xl) {
						grid-column: -1 / 1;
					}
				}

				& ::v-deep(.field-checkbox-main) {
					@include media-breakpoint-up(xl) {
						align-items: center;
					}
				}

				@include media-breakpoint-up(md) {
					display: grid;
					gap: 30px;
					grid-template-rows: 1fr auto;
					grid-template-columns: repeat(4, 1fr);
				}

				@include media-breakpoint-up(lg) {
					grid-template-columns: repeat(3, 1fr);
				}

				@include media-breakpoint-up(xxl) {
					gap: 0 30px;
					margin-bottom: 0;
				}

				@include media-breakpoint-up(xxl) {
					grid-template-columns: 450px 450px 1fr;
				}
			}

			::v-deep .button-submit .button {
				width: 100%;

				@include media-breakpoint-up(md) {
					width: auto;
				}
			}
		}

		::v-deep.form-input {
			@include media-breakpoint-up(md) {
				width: 100%;
			}
		}

		::v-deep.input-name {
			@include media-breakpoint-up(md) {
				grid-area: 1/1/3/3;
			}

			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
				grid-area: 1/1/2/2;
			}
		}

		::v-deep.input-email {
			@include media-breakpoint-up(md) {
				grid-area: 1/3/2/5;
			}

			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
				grid-area: 1/2/2/3;
			}
		}

		::v-deep.input-checkbox {
			margin-bottom: 4rem;

			@include media-breakpoint-landscape {
				margin-bottom: 0;
			}

			@include media-breakpoint-up(md) {
				grid-area: 2/1/3/3;
			}

			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
				grid-area: 2/1/3/3;
			}
		}

		.newsletter-submit {
			margin-bottom: 0;

			@include media-breakpoint-up(md) {
				grid-area: 2/3/3/5;
				align-self: start;
			}

			@include media-breakpoint-up(lg) {
				grid-area: 1/3/2/4;
				justify-self: end;
				align-self: center;
				margin-top: 1rem;
			}
		}

	}

</style>
