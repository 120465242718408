<template>
	<div class="fixed-b2b">
		<base-link class="social-link" :to="'buss-to-buss'" title="Zapraszamy do współpracy">
			{{value.fixedBtn}}
		</base-link>
	</div>
</template>
<script>
export default {
	computed: {
		value () {
			return this.$app.layout.value
		}
	}
}
</script>
<style lang="scss" scoped>
.fixed-b2b {
	@include transition;
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 999;

	::v-deep(a){
		max-width: none!important;
	}

	@include media-breakpoint-up(xl) {
		width: auto;
		right: 15px;
		left: auto;
		max-width: 250px;
		bottom: 10px;
	}

	&:hover{
		transform: translateY(-5px);
	}

	&:after{
		content: '';
		position: relative;
		display: block;
		height: 4px;
		width: 100%;
		background-color: #3D9ED3;
		border-radius: 0 0 2px 2px;
	}

	.social-link{
		color: #fff!important;
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		max-width: 250px;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.08em;
		line-height: 17px;
		padding: 20px 50px;
		border-radius: 2px;
		background-color: #474747;
	}
}
</style>
