<template>
	<base-container>
		<div
			:class="{
				[page.viewAlias]: true
			}"
			class="auth-wrapper"
			v-if="page.ready || ready"
		>
			<div  v-cloak>
				<base-font class="custom-font" variant="heading">{{translate.sign_in.header}}</base-font>
				<transition name="slide-fade" mode="out-in">
					<div key="left-side-full" v-if="page.viewAlias === authViews.signIn">
						<info-box v-if="!!page.view.status.code" :status="page.view.status.code">
							{{page.view.status.message}}
						</info-box>
						<form v-if="page.ready" class="form">
							<div class="form-wrapper"  >
								<input-template wider :input="page.view.form.inputs.email" autocomplete="email" />
								<input-template wider :input="page.view.form.inputs.password" autocomplete="current-password" />
								<base-link to="remind-password" type="gold" underline >{{translate.sign_in.buttons.change_password}}</base-link>
							</div>
							<button-submit @click="page.view.signIn()" :loading="page.view.processing" type="secondary" :rightSlot="true" class="btn-wider">
								<base-font color="white" tag="span">{{translate.sign_in.buttons.login}}</base-font>
							</button-submit>
						</form>
					</div>
					<div key="left-side-min" v-else>
						<base-button  to="auth" :query="$route.query" :params="{ view: authViews.signIn }" type="secondary" :rightSlot="true" class="btn-wider">
							<base-font color="white" clear tag="span">{{translate.sign_in.buttons.already_member}}</base-font>
						</base-button>
						<account-benefits />
					</div>
				</transition>
			</div>
			<span class="divider"></span>
			<div  v-cloak>
				<base-font class="custom-font" variant="heading" tag="h2">{{translate.sign_up.header}}</base-font>
				<transition name="slide-fade" mode="out-in">
					<div key="right-side-min" v-if="page.viewAlias === authViews.signIn">
						<base-button to="auth" :query="$route.query"
								:params="{ view: authViews.signUp }" type="secondary" :rightSlot="true" class="btn-wider">
							<base-font color="white" clear tag="span">{{translate.sign_up.buttons.create_account}}</base-font>
						</base-button>
						<account-benefits v-if="page.mode !== 'checkout'"/>
					</div>
					<div key="right-side-full" v-else>
						<info-box v-if="!!page.view.status.code" :status="page.view.status.code">
							{{page.view.status.message}}
						</info-box>
						<form v-if="page.ready" class="form">
							<div class="form-inner-wrapper">
								<div>
									<div >
										<input-template wider :input="page.view.form.inputs.firstName" autocomplete="name" />
									</div>
									<div >
										<input-template wider :input="page.view.form.inputs.lastName" autocomplete="surname" />
									</div>
									<div >
										<input-template wider :input="page.view.form.inputs.email" />
										<input-template wider :input="page.view.form.inputs.password" />
										<input-template wider :input="page.view.form.inputs.regulations" />
										<input-template wider :input="page.view.form.inputs.newsletter" />
									</div>
								</div>
							</div>
							<button-submit @click="page.view.signUp()" :loading="page.view.processing" type="secondary" :rightSlot="true" class="btn-wider">
								<base-font color="white" tag="span">{{translate.sign_up.buttons.register}}</base-font>
							</button-submit>
						</form>
					</div>
				</transition>
				<template v-if="page.mode === 'checkout'">
					<transition name="slide-fade" mode="out-in">
						<div key="signUp" v-if="page.viewAlias === authViews.signUp">
							<div class="vertical-divider">
								<span class="divider-text">{{translate.sign_up.divider_or}}</span>
							</div>
							<base-button @click="page.strategy.success()" key="6" type="white" :rightSlot="true" class="btn-wider">
								<base-font color="primary" clear tag="span">{{translate.sign_up.buttons.guest}}</base-font>
							</base-button>
						</div>
						<div key="signIn" v-else>
							<div class="vertical-divider">
								<span class="divider-text">{{translate.sign_up.divider_or}}</span>
							</div>
							<base-button @click="page.strategy.success()" key="6" type="white" :rightSlot="true" class="btn-wider">
								<base-font color="primary" clear tag="span">{{translate.sign_up.buttons.guest}}</base-font>
							</base-button>
						</div>
					</transition>
				</template>
			</div>
		</div>
	</base-container>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'
import { InputTemplate } from '@f/core/forms/inputs'
import InfoBox from '~/authorization/front/components/InfoBox'
import { AuthViews } from '~/authorization/front/core/pages/Auth/Auth'

export default {
	components: {
		ButtonSubmit,
		InputTemplate,
		InfoBox
	},
	data () {
		return {
			ready: false
		}
	},
	computed: {
		page () {
			return this.$app.page
		},
		translate () {
			return this.$app.translator.get('auth')
		},
		authViews () {
			return AuthViews
		}
	},
	methods: {
		translations (path) {
			return this.$app.translator.get(`forms.auth.${path}`)
		}
	},
	watch: {
		'page.ready' () {
			this.ready = true
		}
	}
}
</script>
<style lang="scss" scoped>
.auth-wrapper{
	.form {
		& ::v-deep label {
			color: $primary;
		}
		.field-wrapper {
			padding: 0;

			::v-deep .error-wrapper {
				height: 10px;

				&:last-child {
					height: 30px;
				}
			}
		}
	}
	::v-deep .btn-wider {
		.btn {
			width: 100%;
			margin-top: 3rem;
		}
	}
	::v-deep .custom-font {
		@include media-breakpoint-up(lg) {
			font-size: 3rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 3.7rem;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 4.7rem;
		}
	}
	::v-deep .btn {
		width: 100%;
		margin: 0 0 4rem 0;

		&:nth-child(2) {
			margin-top: 4rem;
		}
	}
	::v-deep h2.custom-font {
		@media screen and (max-width: 1023px) {
			margin-top: 0;
		}
	}
}
::v-deep .btn {
	width: 100%;
	margin: 4rem 0;
}
.divider {
	display: none;
	@include media-breakpoint-up(lg) {
		width: .5px;
		display: block;
		height: 300px;
		position: absolute;
		top: 250px;
		left: 50vw;
		background: $gray;
	}
}
.vertical-divider {
	position: relative;
	text-align: center;
	.divider-text {
		background: $gray;
		color: $gray;
		padding-inline: 2rem;
	}
	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: .5px;
		background: $gray;
	}
	.slide-fade-leave-active {
		transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}
	.slide-fade-enter, .slide-fade-leave-to {
		@include media-breakpoint-up(lg) {
			transform: translateY(50px) scale(.98);
			opacity: 0;

		}
	}
	.sign-up {
		flex-direction: column-reverse;
		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
	}
}
</style>
