<template>
    <p
        :class="{valid: option.isValid, invalid: option.isInvalidAndTouched}"
        v-html="option.text"
    ></p>
</template>

<script>
export default {
	props: {
		option: Object
	}
}
</script>

<style lang="scss" scoped>
    p {
        font-size: 1.4rem;
        line-height: 2.1rem;
        margin: 5px 0;
        letter-spacing: 0.5px;
		text-transform: uppercase;
		font-weight: 500;
		color: $gray;
		padding: 0 2rem 0 2rem;
        &.invalid {
            color: $danger;
        }
        &.valid {
            color: $success;
        }
    }
</style>
