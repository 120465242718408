<script>
import BaseMaskInput from '@f/components/BaseMaskInput'

export default {
	extends: BaseMaskInput,
	name: 'BaseMaskInput',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
    input.input {
		font-size: $font-size-base;
		border: none;
		padding: $input-padding;
		width: $input-width;
		height: $input-height;
		padding-right: 30px;
		line-height: $input-line-height;
		background-color: $input-background;
		/* Chrome, Safari, Edge, Opera */
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		&[type=number] {
			-moz-appearance: textfield;
		}

		&::placeholder{
			font-style: italic;
			color: $input-color;
			font-size: $placeholder-size;
		}
		&.disabled,
		&:disabled {
			background-color: $input-disabled-background;
			color: $input-disabled-color;
		}
	}
</style>
