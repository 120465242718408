<template>
	<component class="svg-icon" :is="dynamicComponent"  v-on="$listeners"/>
</template>

<script>
const req = require.context('@f/assets/svg/', true, /\.(svg)$/i)
const modules = {}
req.keys().map(key => {
	const name = key.match(/\w+/)[0]
	modules[name] = req(key)
})

export default {
	props: {
		name: String
	},
	computed: {
		dynamicComponent () {
			return this.name ? modules[this.name] : false
		}
	}
}
</script>
<style lang="scss" scoped>
.svg-icon {
	@include transition;
	path, rect {
		@include transition;
	}
}
</style>
