<template>
		<div class="logo-wrapper" :class="{'show-menu': scrollDirection === 'down'}">
			<base-link class="logo-link" title="Strona główna Oknar Kępno Sp. Z o.o." to="homepage">
				<base-svg name="logo_color" class="navbar-logo"/>
			</base-link>
			<button
				@click.stop="$emit('menuButtonClick')"
				class="mobile-button"
			>
				<div class="mobile-button-box">
					<span class="mobile-button-inner-wrapper"></span>
				</div>
			</button>
		</div>
</template>
<script>

export default {
	data: () => ({
		scrollPosition: 0,
		scrollDirection: 'down'
	}),
	props: {
		activeMobileNav: {
			type: Boolean
		}
	},
	methods: {
		toggleMenu (e) {
			const distanceFromTop = window.scrollY
			if (this.scrollPosition < 200) {
				this.scrollDirection = 'down'
				this.scrollPosition = distanceFromTop
			} else if (!this.activeMobileNav && this.$app.utils.mobile) {
				this.scrollDirection = distanceFromTop > this.scrollPosition ? 'up' : 'down'
				this.scrollPosition = distanceFromTop
			}
		}
	},
	mounted () {
		window.addEventListener('scroll', this.toggleMenu)
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.toggleMenu)
	}
}
</script>
<style lang="scss" scoped>
.logo-wrapper {
	width: 100%;
	height: 63px;
	z-index: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: $level2;
	@include media-breakpoint-up(xl) {
		width: auto;
		position: relative;
		justify-content: flex-start;
		height: 105px;
		padding-right: 20px;
		margin-right: 110px;
	}

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100%;
		background: $white;
		z-index: -1;
		transform: skew(-40deg);
		box-shadow: 0 3px 15px rgba($black, 0.2);
		@include media-breakpoint-up(xl) {
			content: '';
			width: 50vw;
		}
	}
}
	.navbar-logo {
		width: 130px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $white;
		z-index: $level2;
		transition: height 0.4s ease-in;
		transition: .3s ease-in;
		@include media-breakpoint-up(xl) {
			width: 200px;
		}

	}
	.logo-link {
		position: relative;
		padding-right: 90px;
		display: inline-block;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			width: 100vw;
			height: 63px;
			background: $white;
			z-index: -1;
			transform: skew(-40deg) translateY(-50%);
			box-shadow: 0 3px 15px rgba($black, 0.2);
			@include media-breakpoint-up(xl) {
				content: none;
			}
		}
	}
.mobile-button {
	position: absolute;
	top: 20px;
	right: 20px;
	display: inline-block;
	margin: 0;
	cursor: pointer;
	transition: opacity, filter .3s linear;
	border: 0;
	outline: none;
	background-color: transparent;
	-webkit-tap-highlight-color: transparent;
	@include media-breakpoint-up(xl) {
		display: none;
	}
	&-box {
		position: relative;
		display: inline-block;
		width: 19px;
		height: 16px;
	}
	&-inner-wrapper {
		top: -1px;
		&,
		&::before,
		&::after {
			position: absolute;
			width: 19px;
			height: 2px;
			transition: transform .3s ease;
			background-color: $white;
		}
		&::before,
		&::after {
			display: block;
			content: '';
		}
		&::after {
			top: 14px;
		}
		&::before {
			top: 7px;
			transition: transform, opacity .3s ease;
		}
	}
}

</style>
