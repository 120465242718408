<template>
	<div class="socials-wrapper">
		<base-link :url="value.linkedin" class="social-link" title="Sprawdź nasz firmowy profil na Linkedin!">
			<base-svg name="linkedin"/>
		</base-link>
		<base-link :url="value.facebook" class="social-link" title="Zapraszamy na nasz fanpage na facebooku">
			<base-svg name="facebook"/>
		</base-link>
	</div>
</template>
<script>
export default {
	computed: {
		value () {
			return this.$app.layout.value
		}
	}
}
</script>
<style lang="scss" scoped>
	.socials-wrapper {
		display: flex;
		align-items: center;
		gap: 2rem;
		.social-link {
			line-height: normal;
		}
		::v-deep .svg-icon {
			height: 20px;
			width: 20px;
			path {
				fill: $gray-500;
			}
		}
	}
</style>
