<template>
    <button class="button-close button-close-gallery" @click="emitClose" v-if="galleryButton">
            <base-svg name="close" />
    </button>
    <div class="wrapper" v-else>
        <span class="line"></span>
        <button
            class="button-close"
            @click="emitClose"
        >
            <base-svg name="close" />
        </button>
    </div>
</template>
<script>
export default {
	props: {
		galleryButton: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		emitClose (event) {
			this.$emit('close', event)
		}
	},
	mounted () {
		this.keydownHandler = (e) => {
			if (e.key === 'Escape') this.emitClose(e)
		}
		window.addEventListener('keydown', this.keydownHandler)
	},
	destroyed () {
		window.removeEventListener('keydown', this.keydownHandler)
	}
}
</script>
<style lang="scss" scoped>
	.wrapper{
        display: flex;
        align-items: center;
		width: 100%;
		padding: 2rem 2rem 0 2rem;
		@include media-breakpoint-up(xxl) {
			padding: 4rem 3rem 0 3rem;
		}
        &::v-deep .title {
			margin: 0;
			font-size: 18px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 1.2;

			@include media-breakpoint-up(xl) {
				font-size: 28px;
			}
		}

		::v-deep svg {
			width: 18px;
			height: 18px;
		}

		&:hover {
			stroke-width: 2px;
		}
	}
    .button-close {
			width: 30px;
			cursor: pointer;
			background: transparent;
			border: none;
			outline: none;
            &-gallery {
                ::v-deep.svg-icon {
                    g {
                        stroke: $white;
                    }
                }
                &:hover {
                    ::v-deep.svg-icon {
                    g {
                        stroke: $secondary;
                    }
                }
                }
            }
		}

		.line {
			content: '';
			flex: 1;
			margin: 0 20px 0 20px;
			height: 1px;
			border-bottom: 1px solid $gray-300;
		}

	.safari .button-close {
		width: 34px;
	}
</style>
