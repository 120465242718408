<template>
	<section class="copyright" :class="{ b2b: isB2bPage }">
		<div class="inner-wrapper">
			<cms-text value="copyright_text" :props="{ color: 'primary', tag: 'span'}" layout/>
			<span> - realizacja strony internetowej:</span>
			<base-link class="copyright-link" url="https://www.rekinysukcesu.pl/" title="Agencja marketingowa Wrocław - RekinySukcesu.pl">
				Agencja marketingowa Wrocław - RekinySukcesu.pl
			</base-link>
		</div>
	</section>
</template>
<script>

export default {
	props: {
		link: {
			type: String
		}
	},

	computed: {
		isB2bPage () {
			return this.$app.page.alias === 'buss-to-buss'
		}
	}
}
</script>
<style lang="scss" scoped>
.copyright {
	display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	padding: 2rem 1rem 8rem;
	text-align: center;
	color: $primary;
	font-size: 1.4rem;

	&.b2b {
		padding: 2rem 1rem 3rem;
	}

	& * {
		display: inline;
		text-align: left;
	}
	@include media-breakpoint-up(md) {
		font-size: 1.6rem;
		flex-direction: row;
	}
	@include media-breakpoint-up(xl){
		padding: 2rem;
	}
	.inner-wrapper {
		text-align: center;
		font-weight: 500;
		@include media-breakpoint-up(md) {
			text-align: left;
		}
		& a.copyright-link {
			color: $secondary;
			text-transform: inherit;
			&:hover {
				color: $primary
			}
		}
	}
}
</style>
