<template>
	<nav class="client-nav">
		<ul class="client-nav-list">
			<li class="client-nav-item">
				<base-link  class="nav-link" :class="{active: activePage(userProfileViews.orders, userProfileViews['single-order'])}"
							to="user-profile" :params="{ view: userProfileViews.orders }" type="navigation">
					{{ langs.orders.text }}
				</base-link>
			</li>
			<li class="client-nav-item">
				<base-link  class="nav-link" :class="{active: activePage(userProfileViews['client-details'], userProfileViews['client-details-edit'])}"
							to="user-profile" :params="{ view: userProfileViews['client-details'] }" type="navigation">
					{{langs.links.clientData}}
				</base-link>
			</li>
			<li class="client-nav-item">
				<base-link class="nav-link" :class="{active: activePage(userProfileViews['client-change-password'])}" to="user-profile"
						:params="{ view: userProfileViews['client-change-password'] }" type="navigation">
					{{langs.changePassword.text}}
				</base-link>
			</li>
			<li class="client-nav-item logout-btn" @click="logout">
				<base-link class="nav-link" type="navigation">
					{{langs.logout.text}}
				</base-link>
			</li>
		</ul>
	</nav>
</template>
<script>
import { UserProfileViews } from '~/shop/front/core/pages/UserProfile/UserProfile'

export default {
	computed: {
		langs () {
			return this.$app.translator.get('client-profile')
		},
		page () {
			return this.$app.page
		},
		userProfileViews () {
			return UserProfileViews
		}
	},
	methods: {
		activePage (...aliases) {
			return aliases.includes(this.$app.page.route.params.view)
		},
		logout () {
			this.$app.authorization.signOut()
		}
	}
}
</script>
<style lang="scss" scoped>
.client-nav {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	@include media-breakpoint-up(lg) {
		align-items: flex-start;
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		height: 100%;
		width: 1px;
		background: $gray;
		display: none;
		@include media-breakpoint-up(lg) {
			display: block;
		}
	}
	&-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	&-item {
		margin: 0 0 2rem 0;
		&.logout {
			@include media-breakpoint-up(xl) {
				display: none;
			}
		}
	}
}
</style>
