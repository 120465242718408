<template>
	<li class="translate">
		<button class="translate-choosen" @click="toggle">
			<base-font size="xs" tag="span" color="white" weight="bold" uppercase class="translate-lang" :html="language" />
			<base-svg name="arrow_down" :class="{active: activeDropdown}"/>
		</button>
		<div class="translate-collapse" :style="activeDropdown ? wrapperHeight : false "  ref="dropdown" :class="{active: activeDropdown}">
			<ul class="translate-list" ref="innerWrapper">
				<base-translation-item v-for="lang in langs" :key="lang" :country="lang" :activeLang="language" @languageSelected="languageSelectedHandler" />
			</ul>
		</div>
	</li>
</template>
<script>
import BaseTranslationItem from './BaseTranslationItem.vue'
import { wait } from 'utils/mask'
export default {
	components: { BaseTranslationItem },
	data () {
		return {
			activeDropdown: false,
			language: this.$app.language
		}
	},
	methods: {
		countWrapperHeight () {
			return {
				height: this.$refs.innerWrapper.clientHeight + 'px'
			}
		},
		open () {
			this.activeDropdown = !this.activeDropdown
			this.wrapperHeight = this.countWrapperHeight()
			document.body.addEventListener('click', this.handleBodyClick)
		},
		toggle () {
			if (this.activeDropdown) this.close()
			else this.open()
		},
		handleBodyClick (e) {
			if (this.$refs.dropdown.clientHeight) {
				this.close()
			}
		},
		close () {
			this.activeDropdown = false
			document.body.removeEventListener('click', this.handleBodyClick)
		},
		async languageSelectedHandler ($event) {
			await this.languageManager.initTranslator()
			await wait(300)
			this.language = $event
			this.$app._emit('language:change', this.language)
		},
		async setInitialLanguage () {
			this.languageManager.setInitialLanguage()
			if (this.languageManager.initialLanguage) this.language = this.languageManager.initialLanguage
			if (this.language !== this.$app.settings.general.languages.defaultCmsLanguage) await this.languageManager.initTranslator()
		}
	},
	computed: {
		langs () {
			return ['pl', 'en', 'de']
		},
		languageManager () {
			return this.$app.websiteLanguageManager
		}
	},
	async mounted () {
		await this.setInitialLanguage()
	}
}
</script>
<style lang="scss" scoped>
.translate {
	position: absolute;
	top: 2rem;
	left: 2rem;

	@include media-breakpoint-up(xl) {
		position: relative;
		top:auto;
		left: auto;
	}

	&-choosen {
		display: none;
		@include media-breakpoint-up(xl) {
			display: flex;
			padding: 30px 0 19px 0px;
			flex-direction: column;
			align-items: center;
			border: none;
			background: transparent;
			cursor: pointer;
			.translate-lang {
				transition: .3s ease-in;
			}
			&:hover {
				.translate-lang {
					color: $secondary-300;
				}
			}

			::v-deep .svg-icon {
				width: 10px;
				margin-top: 5px;
				&.active {
					transform: rotate(180deg);
					path {
						stroke: $secondary;
					}
				}
				path {
					stroke: $white;
				}
			}
		}
	}
	&-collapse {
		@include media-breakpoint-up(xl) {
			box-shadow: 0 3px 6px rgba($black, .16);
			position: absolute;
			top: 10.6rem;
			left: 50%;
			transform: translateX(-50%);
			height: 0;
			overflow: hidden;
			transition: .3s ease-in;
		}
	}
	&-list {
		display: flex;
		list-style-type: none;
		margin: 0;
		padding: 0;
		gap: 2rem;
		@include media-breakpoint-up(xl) {
			text-align: center;
			gap: 1rem;
			padding: 1rem 1.5rem;
			background: $white;
			border-radius: 2px;
			flex-direction: column;
		}

	}

}
</style>
