<template>
	<div class="button-submit">
		<base-button
			class="button"
			@click="$emit('click')"
			:type="type"
			:icon="loading ? '' : icon"
		>
			<base-svg class="loading-icon" v-if="loading" name="autorenew" />
			<slot v-else/>
		</base-button>
	</div>
</template>

<script>

export default {
	inheritAttrs: false,
	props: {
		loading: Boolean,
		type: String,
		icon: String
	}
}
</script>

<style lang="scss" scoped>
.button-submit {
	display: flex;
	.button {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-top: 0;
		min-width: 330px;
		min-height: 57px;
	}
	svg {
		animation: rotate infinite 1s linear;
		fill: $white;
		margin-left: 5px;
		width: 25px;
		&.loading-icon {
			height: 25px;
			width: 30px;
			margin-right: 0;
		}
	}
	@keyframes rotate {
		from {transform: rotate(0);}
		to {transform: rotate(360deg);}
	}
}
</style>
