<script>
import BaseButton from '~/cms/front/components/BaseButton'

export default {
	extends: BaseButton,
	name: 'BaseButton',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.btn {
	display: inline-block;
	position: relative;
	justify-content: center;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 600;
	cursor: pointer;
	border: none;
	font-family: $primary-font;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.08em;
	padding: 20px 50px;
	border-radius: 2px;
	transition: .3s ease-in;
	white-space: nowrap;
	@include media-breakpoint-up(md) {
		padding: 20px clamp(10px, 8%, 50px);
	}
	@include media-breakpoint-up(xxl) {
		padding: 20px 50px;
	}
	.content-wrapper {
		display: flex;
		align-items: center;
		transition: .3s ease-in;
	}
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 4px;
		transition: .3s ease-in;
		transform-origin: bottom;
	}
	.svg-icon {
		height: 13px;
		width: 13px;
		margin-right: 10px;
	}
    @include media-breakpoint-up(xl) {
        &:hover {
            .content-wrapper {
                transform: translateY(-3px)
            }
            &::after {
                transform: scaleY(1.8);
            }
        }
    }
	&.btn-primary {
		background: $white;
		color: $primary;
		box-shadow: 0 3px 20px rgba($black, .03);
		&::after {
			background: $secondary;
		}
		::v-deep .svg-icon path {
			fill: $primary;
		}
	}
	&.btn-secondary {
		background: $secondary;
		color: $white;
		&::after {
			background: $secondary-500;
		}
		::v-deep .svg-icon path {
			fill: $white;
		}
	}
}
</style>
