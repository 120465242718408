import { rextServiceExtend } from '~/products/front/core/services/RextService'
import { Product } from '~/products/front/core/entities/Product'
const ProductPage = async () => (await import('./core/pages/Product')).ProductPage
const ProductListByType = () => import('./sections/ProductListByType')
const ProductInfoSection = () => import('./sections/ProductInfoSection')
const ColorsSection = () => import('./sections/ColorsSection')
const ProductAdvantagesSection = () => import('./sections/ProductAdvantagesSection')
const ProductDestinySection = () => import('./sections/ProductDestinySection')
const ProductDownloadSection = () => import('./sections/ProductDownloadSection')
const RealizationsGallerySection = () => import('./sections/RealizationsGallerySection')
const ProductTypeSection = () => import('./sections/ProductTypeSection')
const ManyProductsInfoSection = () => import('./sections/ManyProductsInfoSection')
const ProductCatalogSection = () => import('./sections/ProductCatalogSection')

class ProductModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
		app.addPage('product', ProductPage)
	}

	readyToAddEntities (app) {
		app.addEntity('product', Product)
	}

	async beforeInitReactive (app) {
		app.addSection('product_list_type', ProductListByType)
		app.addSection('product_info_section', ProductInfoSection)
		app.addSection('colors_section', ColorsSection)
		app.addSection('product_advantages_section', ProductAdvantagesSection)
		app.addSection('product_destiny_section', ProductDestinySection)
		app.addSection('product_download_section', ProductDownloadSection)
		app.addSection('realization_gallery_section', RealizationsGallerySection)
		app.addSection('many_products_info_section', ManyProductsInfoSection)
		app.addSection('product_type_section', ProductTypeSection)
		app.addSection('product_catalog_section', ProductCatalogSection)
	}
}

export { ProductModule }
