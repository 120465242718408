<template>
	<div class="squad-tile">
		<base-font class="title" tag="h3" color="primary" uppercase >{{ tile.items_title }}</base-font>
	</div>
</template>
<script>
export default {
	props: {
		tile: Object
	}
}

</script>
<style lang="scss" scoped>

.squad-tile {
	background-color: $white;
	border-radius: .2rem;
	padding: 2rem 1.9rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
}
::v-deep .title {
	font-size: 1.6rem;
	font-weight: 400;
	margin-bottom: 0;
	@include media-breakpoint-up(xl) {
      font-size: 1.9rem;
    }
}
</style>
