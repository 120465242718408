<template>
	<div class="dropdown-wrapper" @click="toggle" :class="{active: activeDropdown}">
		<div class="nav-icon-wrapper" v-if="item.icon">
			{{item.icon}}
			<base-svg class="nav-item-icon" :name="item.icon"/>
		</div>
		<div class="nav-item-dropdown">
			<div class="nav-item-dropdown-header">
				<base-font variant="menu" class="nav-position-name">
						{{item.name}}
				</base-font>
				<base-svg name="arrow_down" :class="{active: activeDropdown}" class="nav-arrow-icon"/>
			</div>
			<div class="wrapper" :class="{ 'smaller-wrapper': item.children.length < 6 }" :style="activeDropdown ? wrapperHeight : false " ref="dropdown">
				<div class="inner-wrapper" :class="{ 'inner-small-wrapper': item.children.length < 6 }" ref="innerWrapper">
					<dropdown-group-items
						v-for="(element, i) in item.children"
						:key="element.name"
						:element="element"
						:index="i"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import DropdownGroupItems from './DropdownGroupItems'
export default {
	data () {
		return {
			activeDropdown: false
		}
	},
	components: {
		DropdownGroupItems
	},
	props: {
		item: Object
	},
	computed: {
		isCurrentLangDe () {
			return this.$app.websiteLanguageManager.currentLanguage === 'de'
		}
	},
	methods: {
		countWrapperHeight () {
			if (this.isCurrentLangDe && this.item.children.length > 6 && window.innerWidth < 1600 && window.innerWidth > 1200) {
				return {
					height: '290px'
				}
			} else {
				return {
					height: this.$refs.innerWrapper.clientHeight + 'px'
				}
			}
		},
		open () {
			this.activeDropdown = !this.activeDropdown
			this.wrapperHeight = this.countWrapperHeight()
			if (this.activeDropdown) {
				document.body.addEventListener('click', this.handleBodyClick)
			}
		},
		toggle () {
			if (this.activeDropdown) this.close()
			else this.open()
		},
		handleBodyClick (e) {
			if (this.$refs.dropdown.clientHeight) {
				this.close()
			}
		},
		close () {
			this.activeDropdown = false
			document.body.removeEventListener('click', this.handleBodyClick)
		}
	},
	mounted () {
		if (this.item.children.length > 6 && window.innerWidth > 1200) {
			window.addEventListener('resize', () => {
				if (![...this.$refs.dropdown.classList].includes('small-wrapper')) {
					if (!this.item.children.length < 6 && window.innerWidth < 1600 && window.innerWidth > 1200 && this.isCurrentLangDe && this.activeDropdown) {
						const elements = document.querySelectorAll('.wrapper')
						const element = [...elements][1]

						if (![...element.classList].includes('small-wrapper')) {
							element.style.height = '290px'
						}
					} else if (!this.item.children.length < 6 && window.innerWidth > 1600 && this.isCurrentLangDe && this.activeDropdown) {
						const elements = document.querySelectorAll('.wrapper')
						const element = [...elements][1]

						if (![...element.classList].includes('small-wrapper')) {
							element.style.height = '178px'
						}
					}
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.dropdown-wrapper {
	display: flex;
	width: 100%;
	&.active {
		.nav-item-icon {
			@include media-breakpoint-up(xl) {
				& ::v-deep path {
					fill: $secondary;
				}
			}
		}
		& .nav-link {
			color: $primary;
		}
		.nav-item-dropdown-header {
			background: $gray-300;
			border-left: 6px solid $gray-300;
			@include media-breakpoint-up(xl) {
				background: none;
				border: none;
			}
		}
	}
	@include media-breakpoint-up(xl) {
		flex-direction: column;
        height: 100%;
        justify-content: center;
	}
}
.wrapper {
	height: 0;
	overflow: hidden;
	@include transition;
	@include media-breakpoint-up(xl) {
		background-color: $white;
		position: absolute;
		width: 100%;
		top: 115px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 3px;
		box-shadow: 0 3px 6px rgba($black, .16);
	}
	@include media-breakpoint-up(xxl) {
		top: 120px;
	}
	&.smaller-wrapper {
		@include media-breakpoint-up(xl) {
			width: 60%;
			.inner-wrapper {
				padding: 0;
			}
			::v-deep .child-icon {
				height: 45px;
				margin-bottom: 10px;
			}
		}
	}
}
.inner-wrapper {
	padding: 4rem;
	display: grid;
	place-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 110px;
	column-gap: clamp(50px, 10vw, 70px);
	row-gap: 50px;
    position: relative;
	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(4, 1fr);
	}
	@include media-breakpoint-up(xl) {
		padding: 0;
		display: flex;
		gap: 0;
		align-items: center;
		justify-content: space-evenly;
	}
}
.nav {
	&-icon-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 60px;
		background-color: $gray;
		margin-bottom: auto;
		& ::v-deep svg path {
			fill: $white;
		}
		@include media-breakpoint-up(xl) {
			justify-content: center;
			background-color: transparent;
			height: 45px;
			padding: 0;
			& ::v-deep svg path {
				fill: $gray;
			}
		}
	}
	&-item-dropdown {
		width: 100%;
	}
	&-item-icon {
		width: 25px;
		height: 25px;
	}
	&-item-dropdown-header {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 17px 35px 16px 30px;
		border-left: 6px solid $white;
		@include transition;
		@include media-breakpoint-up(xl) {
			padding: 0;
			height: 60px;
			height: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			border: none;
		}
	}
	&-arrow-icon {
		width: 10px;
		@include transition;
		&.active {
			transform: rotate(180deg);
			& ::v-deep path {
				stroke: $secondary;
			}
		}

		@include media-breakpoint-up(xl) {
			position: static;
			margin-top: .95rem;
			position: absolute;
			bottom: -1rem;
			& ::v-deep path {
				stroke: $white;
			}
		}
	}
	&-position-name {
		display: block;
		width: 100%;
		height: 100%;
		line-height: normal;
	}
	&-link {
		position: relative;
		color: $secondary;
		text-decoration: none;
		display: flex;
		align-items: center;
		cursor: pointer;
		@include transition;
		color: $primary;
		@include media-breakpoint-up(xl) {
			color: $white;
		}
	}
}
</style>
<style lang="scss">
#app.de .nav-item-dropdown {
	@include media-breakpoint-up(xl) {
		@media (max-width: 1600px) {
			.wrapper {
				//height: fit-content;
				//height: 290px;
			}
			.inner-wrapper {
				display: grid;
				grid-template-columns: repeat(6, 1fr);
				padding: 2rem;
				row-gap: 3rem;
			}
			.inner-small-wrapper {
				display: flex;
				grid-template-columns: repeat(4, 1fr);
				padding: 0;
				gap: 0;
			}
			.child {
				height: fit-content;
				display: block;
				align-self: auto;
				flex-direction: unset;
			}
		}
	}
}
</style>
