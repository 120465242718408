
<template>
	<div class="map" :class="{b2b: getPage === 'buss-to-buss'}">
            <base-heading v-if="getPage !== 'buss-to-buss'" layout heading="mapData.0.main_title" subheading="mapData.0.subtitle" v-background="backgroundOverlay" v-animate="`opacity`"/>
			<gmap-map
                ref="mapRef"
                :center="{lat: parseFloat(centerTo.lat), lng: parseFloat(centerTo.lng)}"
                :zoom="11"
                map-type-id="roadmap"
                style="width: 100%; height: 400px"
            >
                <gmap-info-window v-if="infoContent" :options="infoOptions" :position="infoPosition" :opened="infoOpened" @closeclick="infoOpened=false">
                    <div class="infogmap-wrapper">
                        <base-font class="title" size="sm" family="primary" weight="bold">{{infoContent.name}}</base-font>
                        <div class="content" v-html="infoContent.content"></div>
						<base-link :url="roadData" class="road-link" title="Siedziba Oknar Kępno Sp. Z o.o." >{{lang.road}}</base-link>
                    </div>
                </gmap-info-window>
                <gmap-marker
                    :key="index"
                    v-for="(m, index) in pins"
                    :position="getPosition(m)"
                    @click="toggleInfo(m, index)"
                />
            </gmap-map>
	</div>
</template>
<script>
import { gmapApi as GmapApi, loadGmapApi } from 'vue2-google-maps'
import pin from '@f/assets/images/oknar-pin.png'
import BaseHeading from '../headings/BaseHeading/BaseHeading.vue'

export default {
	components: {
		BaseHeading
	},
	data () {
		return {
			markerOptions: {
				url: pin,
				size: { width: 40, height: 50, f: 'px', b: 'px' },
				scaledSize: { width: 40, height: 50, f: 'px', b: 'px' }
			},
			infoOptions: {
				pixelOffset: {
					width: 0,
					height: -35
				}
			},
			infoPosition: null,
			infoContent: null,
			infoOpened: false,
			infoCurrentKey: null
		}
	},
	props: {
		pins: Array,
		isB2b: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	computed: {
		getPage () {
			return this.$app.page.alias
		},
		roadData () {
			return this.$app.settings.general.contact.road_data
		},
		centerTo () {
			return this.pins[0]
		},
		lang () {
			return this.$app.translator.get('europe-map')
		},
		backgroundOverlay () {
			const path = '/products/product-07.png'
			return {
				src: path,
				breakpoints: {
					base: { width: 720, height: 650 },
					lg: { width: 825, height: 451 },
					xl: { width: 1920, height: 515 }
				}
			}
		}
	},
	methods: {
		getPosition (marker) {
			return {
				lat: parseFloat(marker.lat),
				lng: parseFloat(marker.lng)
			}
		},
		toggleInfo (marker, key) {
			this.infoPosition = this.getPosition(marker)
			this.infoContent = marker
			if (this.infoCurrentKey === key) {
				this.infoOpened = !this.infoOpened
			} else {
				this.infoOpened = true
				this.infoCurrentKey = key
			}
		},
		async setMap () {
			if (this.pins.length > 1) {
				const map = await this.$refs.mapRef.$mapPromise
				const gmap = new GmapApi().maps
				const bounds = new gmap.LatLngBounds()

				this.pins.forEach(pin => {
					const latLng = new gmap.LatLng({ lat: parseFloat(pin.lat), lng: parseFloat(pin.lng) })
					bounds.extend(latLng)
				})

				map.fitBounds(bounds)
				map.panToBounds(bounds)
			}
		},
		invokeMap () {
			return new Promise(function (resolve, reject) {
				setTimeout(function () {
					loadGmapApi({
						key: process.server ? '' : window.__CONFIG__.googleMapKey,
						language: 'pl'
					})
				}, 5000)
			})
		}
	},
	async mounted () {
		await this.invokeMap()
		await this.setMap()
	},
	async prefetch () {
		await this.$app.translator.prefetch('europe-map')
	}
}
</script>
<style lang="scss" scoped>
.map {
	display: flex;
	justify-content: center;
    flex-direction: column;
	height: 430px;
	overflow: hidden;
	position: relative;
	@include media-breakpoint-up(lg) {
        height: 500px;
	}

	&.b2b {
		height: auto;
		display: block;
	}
    ::v-deep .heading-wrapper{
        margin: 0;
        padding: 4rem 0;
        @include media-breakpoint-up(xl) {
            padding: 8rem 0;
        }
    }
	.road-link {
		text-transform: Initial;
	}
}
.infogmap-wrapper {
	font-size: 3rem;
	& ::v-deep .title {
		margin: 0;
		text-transform: initial;
	}
	& .content {
		font-size: 1.6rem;
		color: $primary;
		font-family: $primary-font;
	}
}
</style>
