import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getRealizations (q) {
			const query = getQuery({
				relations: [],
				where: q
			})
			const { data, status } = await this.http.get(
				`/realization${query}`
			)

			if (status !== 200) return { error: true }
			return data
		}

		async getRealization (alias) {
			const { data, status } = await this.http.get(
				`/realization/${alias}`
			)

			if (status !== 200) return { error: true }
			return data
		}
	}
}
