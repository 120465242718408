<script>
import BaseLink from '~/cms/front/components/BaseLink'
export default {
	extends: BaseLink,
	name: 'BaseLink',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.link:not(.nav-link) {
	cursor: pointer;
	font-size: 1.6rem;
	font-weight: bold;
	letter-spacing: 0.04em;
	line-height: 2.6rem;
	text-decoration: underline;
	@include transition;
	&.link-primary {
		color: $secondary;
	}
	&.link-secondary {
		color: $primary;
	}
	&.link-white {
		color: $white;
	}
    &.link-blog {
        color: $white;
    }
	@include media-breakpoint-up (xl) {
		&.link-secondary:hover {
			color: $secondary;
		}
		&.link-primary:hover {
			color: $secondary-300;
		}
		&.link-white:hover {
			color: $secondary;
		}
	}
	&.no-underline {
		text-decoration: none;
	}
}
</style>
