class Realization {
	static async fetch (query) {
		const service = this.app.getService('rext')
		const data = await service.getRealizations(query)

		if (data.error) return []

		const Realization = this.app.getEntity('realization')

		return {
			realizations: data.map(realization => Realization.create(realization))
		}
	}

	static async fetchOne (alias) {
		const service = this.app.getService('rext')
		const data = await service.getRealization(alias)

		if (data.error) return null

		const Realization = this.app.getEntity('realization')
		return Realization.create(data)
	}

	static create (data = {}) {
		const Realization = this.app.getEntity('realization')
		const instance = new Realization()

		Object.entries(data).forEach(([property, value]) => {
			if (property in instance) instance[property] = value
		})

		return instance
	}

	id
	name
	alias
	thumbnail
	data
	isHighlighted
    published
	category

	get app () {
		return this.constructor.app
	}
}

export { Realization }
