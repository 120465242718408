<template>
	<base-link :to="to" type="secondary" class="footer-link link" >
		{{name}}
	</base-link>
</template>
<script>
export default {
	props: {
		name: String,
		to: String
	}
}
</script>
<style lang="scss" scoped>
.footer-link.link {
	display: block;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 1.2rem;
	color: $gray;
	margin-bottom: 1.8rem;
	font-weight: 500;
	line-height: 1.6rem;
	@include media-breakpoint-up(xl) {
		font-size: 1.4rem;
		@include transition;
		&:hover {
			color: $secondary;
            text-decoration: underline;
		}
	}
}
</style>
