<template>
  <div id="app">
	<template v-if="$app.layout">
		<component v-if="$app.layout" :is="$app.layout.component">
			<component v-if="$app.page" :is="$app.page.component" />
		</component>
	</template>
	<component v-else-if="$app.page" :is="$app.page.component" />
	<base-modal/>
  </div>
</template>

<script>
import BaseModal from './components/BaseModal'
export default {
	metaInfo: {
		script: [
			{
				src: '/browserDetect.js',
				type: 'text/javascript'
			}
		]
	},
	components: {
		BaseModal
	}
}
</script>

<style lang="scss">
@import '@f/assets/scss/base/index.scss';

#app {
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#chat-application{
	bottom: 55px!important;
	right: 0!important;

	&.b2b {
		bottom: 0!important;
	}

	@include media-breakpoint-up(xl) {
		bottom: 90px!important;
	}
}
</style>
