<script>
import Vue from 'vue'
import { getValue, setValue } from 'utils/objects'
export default {
	props: {
		prioritizedValue: String,
		value: String,
		layout: Boolean,
		props: Object
	},
	data: () => ({
		templateRender: () => '',
		compiledValue: () => ''
	}),
	computed: {
		html () {
			let value
			if (this.layout) value = this.$app.layout.value
			else if (this.prioritizedValue) return this.prioritizedValue
			else value = this.$app.page.value

			return getValue(this.value, value, '')
		},
		mode () {
			if (this.layout) return 'layout'
			return 'page'
		},
		editable () {
			return this.$app.wyswig.mode === this.mode
		}
	},
	methods: {
		saveValue (e) {
			if (!this.editable) return false
			let wrapper = this.$app.page
			if (this.layout) wrapper = this.$app.layout
			setValue(this.value, wrapper.value, e.target.innerHTML)
		},
		rerender () {
			this.$options.staticRenderFns = []
			var compiledTemplate = Vue.compile(`<base-font class="ql-editor" v-bind="props">${this.compiledValue}</base-font>`)
			this.templateRender = compiledTemplate.render
			this.$options.staticRenderFns = compiledTemplate.staticRenderFns
		},
		mapLinks () {
			let mappedValue = this.html
			const aElementsList = Array.from(this.html.matchAll(/<a(.*?)<\/a>/g))
			for (const i in aElementsList) {
				const element = aElementsList[i]

				let componentElement = element[0]
				const aTags = Array.from(componentElement.matchAll(/<a(.*?)a>/g))
				for (const j in aTags) {
					const tag = aTags[j][0]
					let newTagVal

					if (tag.includes('tel:') || tag.match(/"\B#([a-z0-9]{2,})(?![~!@#$%^&*()=+_`\-\\|\\/'\\[\]\\{\\}]|[?.,]*\w)"/)) {
						newTagVal = tag.replace('_blank', '_self')
					} else if (!tag.includes('data-base-link=""')) continue
					else {
						newTagVal = tag
							.replaceAll('<a', '<base-link')
							.replaceAll('</a>', '</base-link>')
							.replaceAll('href', 'to')
							.replaceAll('data-base-link=""', '')

						if (newTagVal.includes('parameter')) {
							const parameterValue = newTagVal.match(/parameter="(.*?)"/)[1]
							newTagVal = newTagVal.replace(/parameter=".+?"/, `:params="{alias: '${parameterValue}'}"`)
						}
					}
					componentElement = componentElement.replace(tag, newTagVal)
					mappedValue = mappedValue.replace(tag, componentElement)
				}
			}
			this.compiledValue = mappedValue
		}
	},
	render () {
		return this.templateRender()
	},
	watch: {
		html: 'rerender'
	},
	mounted () {
		this.mapLinks()
		this.rerender()
	}
}
</script>

<style scoped lang="scss">
:deep(.link) {
	text-transform: none;
}
</style>
