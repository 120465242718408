<template>
	<div class="field-wrapper" :info="input.info"
	>
		<div class="field-label">
			<base-font
				variant="label"
				color="white"
				:for="input.name"
				@click.stop="$emit('click')"
			>{{input.label}}:</base-font>
		</div>
		<div class="field-inner-wrapper">
			<base-svg name="star" class="star" v-for="key in [5, 4, 3, 2, 1]" :key="key" @click="setValue(key)" :class="{'disabled-star': key > input.value, 'enabled-star': key <= input.value }" />
		</div>
		<base-error :error="input.error" />
	</div>
</template>

<script>
import mixin from '../utils/input.mixin'
import BaseError from '@f/components/BaseError'

export default {
	mixins: [mixin],
	components: {
		BaseError
	},
	methods: {
		setValue (value) {
			this.input.value = value
			this.input.markAsTouched()
		}
	}
}
</script>
<style lang="scss" scoped>
@import "../utils/fields.scss";
.field-wrapper {
	.field-inner-wrapper {
		width: fit-content;
		display: flex;
		flex-direction: row-reverse;
		&:hover {
			cursor: pointer;
			.star {
				fill: $gray;
			}
		}
		.star {
			&:hover {
				fill: $secondary;
			}
		}
	}
	.star {
		width: 2rem;
		height: 2rem;
		fill: $gray;
		transition: none;
		&:hover {
			& ~ .star {
				fill: $secondary;
			}
		}
		& ::v-deep path {
			transition: none;
		}

		&.disabled-star{
			fill: $gray;

			&:hover {
				fill: $secondary;
			}
		}
		&.enabled-star {
			fill: $secondary;
		}
	}
}
</style>
