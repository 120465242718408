<template>
	<div class="progress-bar-wrapper">
		<base-container>
			<div class="progress-bar">
				<span
					v-for="item in barItems"
					:key="item.name"
					class="progress-item"
					:class="{active: item.to === $app.page.route.meta.alias}"
				>
				{{item.name}}
				</span>
			</div>
		</base-container>
	</div>
</template>
<script>
export default {
	computed: {
		barItems () {
			return this.$app.layout.value['checkout-progress'].checkout_progress
		}
	}
}
</script>
<style lang="scss" scoped>
	.progress-bar-wrapper {
		display: none;
		position: absolute;
		bottom: -3.3rem;
		left: 0;
		width: 100%;
		z-index: $level1;

		@include media-breakpoint-up(md) {
			display: block;
		}
		.progress-bar {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			cursor: default;

			.progress-item{
				position: relative;
				padding-top: 1.5rem;
				font-weight: 500;
				color: $gray;
				@include transition;

				&.active {
					color: $secondary;
					font-weight: 700;
				}

				&::before, &::after {
					content: '';
					position: absolute;
					height: 3px;
					top: -1px;
					right: 50%;
					@include transition;

				}
				&.active::before {
					background: $secondary;
					width: 100vw;
					border-right: 5px solid rgba($secondary, .5);

				}
			}
		}
	}
</style>
