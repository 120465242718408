<template>
	<li class="nav-item" :class="{'active': isActive, 'nav-item-dropdown': this.hasChildren}">
		<template v-if="hasChildren">
			<item-with-children :item="item" />
		</template>

		<template v-else>
			<base-link :to="item.to" class="nav-link" :class="{'homepage': item.icon}">
				<div class="nav-icon-wrapper" >
					<base-svg class="nav-item-icon" :name="item.icon"/>
				</div>
				<base-font
					variant="menu"
					class="nav-position-name"
				>
					{{item.name}}
				</base-font>
			</base-link>
		</template>
	</li>
</template>
<script>
import ItemWithChildren from './ItemWithChildren'
export default {
	components: {
		ItemWithChildren
	},
	computed: {
		isActive () {
			return this.item.to.includes(this.$route.meta.alias) || this.isChildrenActive || this.isOfferActive || this.isBlogCategory
		},
		isChildrenActive () {
			return this.item.children.find(child => child.link.includes(this.$route.meta.alias))
		},
		isOfferActive () {
			return this.item.children.find(child => child.link.includes('career')) && this.$route.meta.alias === 'offer'
		},
		hasChildren () {
			return !!this.item.children?.length
		},
		isBlogCategory () {
			return this.$app.page.route.meta.alias.includes(this.item.to)
		}
	},
	props: {
		item: {
			type: Object
		}
	}
}
</script>
<style lang="scss" scoped>
.nav {
	::v-deep &-item-icon {
		width: 16px;
		height: 16px;
		path {
			fill: $white;
		}
	}
	&-item {
		display: flex;
		align-items: center;
		text-transform: uppercase;
		height: 100%;
		border-bottom: 1px solid $gray-300;
		&.active {
			::v-deep .nav-position-name {
				color: $secondary;
			}
			::v-deep .nav-arrow-icon {
				path {
					stroke: $secondary;
				}
			}
			::v-deep .nav-item-icon {
				path {
					fill: $secondary-300;
				}
			}
		}
		@include media-breakpoint-up(xl) {
			cursor: pointer;
			border: none;

			&:hover {
				::v-deep .nav-position-name {
					color: $secondary-300;
				}
			}
		}
		.nav-link {
			font-weight: 500;
			line-height: 1.7rem;
			text-decoration: none;
            @include media-breakpoint-up(xl) {
                height: 100%;
                display: flex;
                align-items: center;
            }
		}
		&:not(.nav-item-dropdown) {
			border-left: 6px solid $white;
			padding: 0;
			@include media-breakpoint-up(xl) {
				border: none;
			}
		}
		::v-deep .nav-position-name {
			line-height: 1.7rem;
			transition: .3s ease-in;
			@include media-breakpoint-up(xl) {
				color: $white;
				padding: 0;
			}
		}
		.nav-item-icon {
			display: none;
			@include media-breakpoint-up(xl) {
				display: inline-block;
			}
		}
		&.active {
			border-left: 6px solid $secondary-500;
			@include media-breakpoint-up(xl) {
				border: none;
			}
		}
	}
	&-link {
        padding: 17px 35px 16px 30px;
        width: 100%;
        @include media-breakpoint-up(xl) {
            padding: 0;
            width: initial;
        }
		&.homepage ::v-deep .nav-position-name {
			@include media-breakpoint-up(xl) {
				display: none;
			}
		}
		@include media-breakpoint-up(xl) {
			justify-content: center;
			color: $white;

		}
	}
}
</style>
