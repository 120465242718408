<template>
	<header class="simplified-header">
		<base-container>
			<base-link to="homepage" class="nav-link">
				<base-svg class="navbar-logo" name="nimphy_logo"/>
			</base-link>
			<progress-bar v-if="$app.page.showCheckoutProgressBar"/>
		</base-container>
	</header>
</template>
<script>
import ProgressBar from '../ProgressBar'
export default {
	components: { ProgressBar }
}
</script>
<style lang="scss" scoped>
	.simplified-header{
		width: 100%;
		border-bottom: 1px solid $gray;
		background: $gray;
		position: relative;

		.nav-link{
			padding: 3rem 0;
			display: inline-block;
			height: 122px;
		}
		::v-deep.navbar-logo {
			width: 214px;
		}
	}
</style>
