
<template>
	<component
		:is="tag"
		:href="path"
        :title="title"
		class="btn"
		:class="[
			type && `btn-${type}`,
			marginReset ? 'margin-reset' : ''
		]"
		@click.prevent="handleClick()"
	>
	<div class="content-wrapper">
		<slot v-if="icon" name="icon">
			<base-svg class="btn-icon"  :name="icon"/>
		</slot>
		<slot />
	</div>
	</component>
</template>

<script>
import BaseSvg from '@f/components/BaseSvg'
import { NavigationMixin } from '~/cms/front/mixins/NavigationMixin'

export default {
	components: {
		BaseSvg
	},
	mixins: [NavigationMixin],
	props: {
		type: {
			type: String,
			default: 'primary'
		},
		marginReset: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String
		}
	}
}
</script>
