<template functional>
	<component
		v-if="props.html"
		:is="$options.tag(props)"
		:ref="data.ref"
		:class="$options.classes(props, data)"
		:style="[data.style, data.staticStyle]"
		v-bind="data.attrs"
		v-on="listeners"
		v-html="props.html"
	/>
	<component
		v-else
		:is="$options.tag(props)"
		:ref="data.ref"
		:class="$options.classes(props, data)"
		:style="[data.style, data.staticStyle]"
		v-bind="data.attrs"
		v-on="listeners"
	>
		<slot />
	</component>
</template>

<script>
import BaseFont from '@f/components/BaseFont'
export const tags = ['', 'div', 'a', 'p', 'span', 'label', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'li']
export const colors = ['primary', 'secondary', 'white', 'gray', 'grayLight']
export const fontFamily = ['primary']
export const weights = ['', 'bold', 'light', 'medium', 'semiBold', 'regular']
export const sizes = ['', 'smallest', 'product-detail', 'xs', 'sm', 'md', 'lg', 'xl', 'number', 'xxl', 'slider-subheading']
export const variants = {
	heading: {
		color: 'gray',
		tag: 'h2',
		size: 'xl',
		family: 'primary',
		weight: 'bold',
		uppercase: true
	},
	'blog-heading': {
		color: 'gray',
		tag: 'h1',
		size: 'lg',
		family: 'primary',
		weighy: 'bold',
		uppercase: true
	},
	subheading: {
		color: 'grayLight',
		tag: 'span',
		size: 'subheading',
		family: 'primary',
		weight: 'light',
		uppercase: true
	},
	paragraph: {
		color: 'primary',
		tag: 'p',
		size: 'sm',
		family: 'primary'
	},
	label: {
		color: 'primary',
		tag: 'label',
		size: 'sm',
		weight: 'bold',
		family: 'primary'
	},
	'small-heading': {
		color: 'primary',
		size: 'md',
		family: 'primary',
		tag: 'h4',
		uppercase: true,
		weight: 'bold'
	},
	'medium-heading': {
		color: 'primary',
		tag: 'h3',
		size: 'lg',
		family: 'primary',
		uppercase: true,
		weight: 'light'
	},
	slider: {
		color: 'white',
		tag: 'h2',
		size: 'xxl',
		family: 'primary',
		uppercase: true,
		weight: 'bold',
		marginReset: true

	},
	menu: {
		tag: 'span',
		size: 'menu',
		family: 'primary',
		uppercase: true,
		weight: 'medium'
	},
	small: {
		color: 'white',
		tag: 'span',
		size: 'xs',
		family: 'primary',
		uppercase: true,
		weight: 'semiBold'
	},
	'slider-subheading': {
		color: 'primary',
		tag: 'span',
		size: 'slider-subheading',
		family: 'primary',
		uppercase: true,
		weight: 'light'
	},
	number: {
		color: 'primary',
		tag: 'span',
		size: 'number',
		family: 'primary',
		weight: 'bold',
		uppercase: false
	},
	listItem: {
		color: 'secondary',
		tag: 'li',
		family: 'primary',
		weight: 'medium',
		size: 'md'
	},
	fileType: {
		color: 'white',
		tag: 'span',
		family: 'primary',
		weight: 'semiBold',
		uppercase: true,
		size: 'smallest'
	}
}

export default {
	extends: BaseFont,
	name: 'BaseFont',
	globalComponent: true
}
</script>

<style lang="scss" scoped>
.fs {
	letter-spacing: 0.04em;
	&-xxl {
		font-size: 2.2rem;
		line-height: 2.6rem;
		@include media-breakpoint-up(sm) {
			font-size: 2.6rem;
			line-height: 2.9rem;
		}
		@include media-breakpoint-up(xl) {
			font-size: 5.6rem;
			line-height: 6.3rem;
			margin-bottom: .5rem
		}
		@include media-breakpoint-up(xxl) {
			font-size: 7.8rem;
			line-height: 9.4rem;
			margin-bottom: 1rem;
		}
	}
	&-xl {
		font-size: 2.2rem;
		line-height: 2.6rem;
		margin-bottom: 1rem;
		@include media-breakpoint-up(xl) {
			font-size: 4.4rem;
			line-height: 5.3rem;
		}
	}
	&-number {
		font-size: 3rem;
		line-height: 3.6rem;
		@include media-breakpoint-up(xl) {
			line-height: 4.8rem;
			font-size: 3.5rem;

		}
		@include media-breakpoint-up(xxl) {
			font-size: 4rem;
		}
	}
	&-lg {
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 2rem;
		@include media-breakpoint-up(xxl) {
			font-size: 3.1rem;
			line-height: 3.7rem;
			margin-bottom: 4rem;
		}
	}
	&-menu {
		font-size: 1.4rem;
		line-height: 1.7rem;
	}
	&-slider-subheading {
		font-size: 1.3rem;
		line-height: 1.6rem;
		@include media-breakpoint-up(xxl) {
			font-size: 3.1rem;
			line-height: 3.7rem;
		}
        @include media-breakpoint-up(xl) {
            font-size: 2.9rem;
            line-height: 3.5rem;
        }
	}
	&-md {
		font-size: 1.6rem;
		line-height: 2.1rem;
		margin-bottom: 1rem;
		@include media-breakpoint-up(xl) {
			font-size: 1.9rem;
			line-height: 2.3rem;
			margin-bottom: 2rem;
		}
	}
	&-subheading {
		line-height: 2.1rem;
		font-size: 1.2rem;
		margin-bottom: 0;
		@include media-breakpoint-up(xl) {
			font-size: 1.9rem;
			line-height: 2.3rem;
		}
	}
	&-sm {
		font-size: 1.4rem;
		line-height: 2.6rem;
		@include media-breakpoint-up(xl) {
			font-size:  1.6rem;
		}
	}
	&-xs {
		font-size: 1.2rem;
		line-height: 1.6rem;
		letter-spacing: 0.08em;
		@include media-breakpoint-up(xl) {
			font-size: 1.4rem;
			line-height: 1.7rem;
		}
	}
	&-smallest {
		font-size: 1.2rem;
		line-height: 1.4rem;
		letter-spacing: .04em;
	}
	&-product-detail {
		font-size: 1.4rem;
		line-height: 1.7rem;
		@include media-breakpoint-up(xl) {
			font-size: 2.6rem;
			line-height: 2.6rem;
		}
	}
}
.ff {
	&-primary {
		font-family: $primary-font;
	}
}
.fc {
	&-primary {
		color: $primary;
	}
	&-secondary {
		color: $secondary;
	}
	&-white {
		color: $white;
	}
	&-gray {
		color: $gray-500;
	}
	&-grayLight {
		color: $gray;
	}
}

.fuc {
	text-transform: uppercase;
}
.fi {
	font-style: italic;
}
.fw {
	&-bold {
		font-weight: 700;
	}
	&-semiBold {
		font-weight: 600;
	}
	&-medium {
		font-weight: 500;
	}
	&-regular {
		font-weight: 400;
	}
	&-light {
		font-weight: 300;
	}
}
.mr {
	margin-block: 0!important;
}

</style>
