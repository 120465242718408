<template>
	<div class="wrapper">
		<base-font class="name" tag="h3" color="secondary" uppercase>{{ specialist.specialist_name }}</base-font>
		<base-font class="type" tag="h3" color="primary" uppercase size="md">{{ specialist.specialist_type }}</base-font>
		<div class="contact-item">
			<base-link class="link" :url="`tel:${removeSpaces(specialist.specialist_phone)}`" :title="specialist.specialist_phone">
				<base-svg :name="specialist.specialist_phone_icon" class="icon"/>
				<base-font class="phone" tag="span" color="primary">{{ specialist.specialist_phone }}</base-font>
			</base-link>
			<base-link class="link" :url="`mailto:${specialist.specialist_mail}`" :title="specialist.specialist_mail">
				<base-svg :name="specialist.specialist_mail_icon" class="icon"/>
				<base-font class="mail" tag="span" color="primary">{{ specialist.specialist_mail }}</base-font>
			</base-link>
		</div>
	</div>
</template>
<script>
import BaseLink from '~/cms/front/components/BaseLink/BaseLink.vue'
export default {
	components: { BaseLink },
	props: {
		specialist: Object
	},
	methods: {
		removeSpaces (string) {
			return string.replaceAll(' ', '')
		}
	}
}

</script>
<style lang="scss" scoped>
.wrapper {
	padding: 3rem 2rem 4rem 2.7rem;
	background-color: $white;
	border-radius: .2rem;
	border-top: .5rem solid $secondary;
	width: 100%;

	::v-deep .name {
		font-size: 2.6rem;
		line-height: 3.1rem;
		letter-spacing: 0.04em;
		font-weight: bold;
		margin-bottom: .5rem;

		@include media-breakpoint-up(lg) {
			font-size: 1.6rem;
		}

		@include media-breakpoint-up(xl) {
			font-size: 2.6rem;
		}
	}
	::v-deep .type {
		font-size: 1.6rem;
		line-height: 2.6rem;
		letter-spacing: 0.04em;
		font-weight: 400;
		margin-bottom: 4rem;

		@include media-breakpoint-up(lg){
			line-height: 1.8rem;
		}

		@include media-breakpoint-up(xl){
			line-height: 2.6rem;
		}
	}

	.contact-item {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		.link {
			display: flex;
			align-items: center;
			gap: 2rem;
			text-decoration: none;
			@include transition;

			@include media-breakpoint-up(lg){
				gap: 1rem;
			}

			@include media-breakpoint-up(xxl) {
				gap: 2rem;
			}

			::v-deep span {
				@include transition;
			}

			&:hover ::v-deep span {
				color: $secondary;
			}
		}
		.icon {
			max-width: 2.5rem;
			min-width: 2.5rem;

			@include media-breakpoint-up(md) {
				max-width: 3.4rem;
			}

			@include media-breakpoint-up(lg) {
				max-width: 2.4rem;
			}

			@include media-breakpoint-up(xl) {
				max-width: 3.4rem;
			}
		}

		::v-deep .phone {
			font-size: 2.6rem;
			letter-spacing: 0.04em;
			line-height: 4.8rem;
			font-weight: bold;
			margin-bottom: 0;

			@include media-breakpoint-up(md) {
				font-size: 3.3rem;
			}

			@include media-breakpoint-up(lg) {
				font-size: 2.3rem;
			}

			@include media-breakpoint-up(xl){
				font-size: 3.3rem;
			}

			@include media-breakpoint-up(xxl) {
				font-size: 4rem;
			}
		}
		::v-deep .mail {
			font-size: 1.8rem;
			letter-spacing: 0.04em;
			line-height: 3.1rem;
			font-weight: 400;
			margin-bottom: 0;
			text-transform: lowercase;

			@include media-breakpoint-up(md) {
				font-size: 2.6rem;
			}

			@include media-breakpoint-up(lg) {
				font-size: 2rem
			}

			@include media-breakpoint-up(xl) {
				font-size: 2.6rem;
			}
		}
	}
}
</style>
