import { BlogModule } from '~/blog/front/index'
import { CMSModule, installComponents } from '~/cms/front/index'
import { WebsiteModule } from '~/website/front/index'
import { NewsletterModule } from '~/newsletter/front/index'
import { ContactFormModule } from '~/contactForm/front/index'
import { TranslationsModule } from '~/translations/front/index'
import { SettingsModule } from '~/settings/front/index'
import { ProductModule } from '~/products/front'
import { RealizationModule } from '~/realizations/front'
import { CareerModule } from '~/career/front/index'

function useModules (app, Vue) {
	installComponents(Vue)
	app.use(new CMSModule())
	app.use(new WebsiteModule(app))
	app.use(new BlogModule())
	app.use(new NewsletterModule())
	app.use(new ContactFormModule())
	app.use(new TranslationsModule())
	app.use(new SettingsModule())
	app.use(new ProductModule())
	app.use(new RealizationModule())
	app.use(new CareerModule())
}
export {
	useModules
}
