import { rextServiceExtend } from '~/realizations/front/core/services/RextService'
import { Realization } from '~/realizations/front/core/entities/Realization'
const RealizationsPage = async () => (await import('./core/pages/RealizationsPage')).RealizationsPage

class RealizationModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
		app.addPage('realizations', RealizationsPage)
	}

	readyToAddEntities (app) {
		app.addEntity('realization', Realization)
	}
}

export { RealizationModule }
