<template>
	<div class="wrapper">
		<base-link class="link-wrapper" :to="category.link" :title="category.name">
			<base-svg :name="category.icon" class="icon"/>
			<base-font class="title" tag="h3" color="primary" uppercase >{{ category.name }}</base-font>
		</base-link>
	</div>
</template>
<script>
import BaseLink from '~/cms/front/components/BaseLink/BaseLink.vue'
export default {
	components: { BaseLink },
	props: {
		category: Object
	}
}

</script>
<style lang="scss" scoped>

.wrapper {
	width: 100%;
	@include media-breakpoint-up(xl) {
		padding: 10px;
	}
}
.link-wrapper {
	display: flex;
	gap: 0 4rem;
	align-items: center;
	text-decoration: none;
	padding: 2rem 0;
	width: 100%;
	height: 100%;
	@include transition;

	@include media-breakpoint-up(xl) {
		justify-content: initial;
		gap: 0 2rem;
		&:hover .icon {
			transform: scale(1.1);
		}
    }
	@include media-breakpoint-up(xxl) {
		gap: 0 4rem;
    }

	svg{
		max-height: 70px;
	}
}

::v-deep.icon {
	max-width: 5rem;
	will-change: transform;

	@include media-breakpoint-up(xl) {
      max-width: 6.6rem;
    }

	path {
		fill: $secondary;
	}
}
::v-deep .title {
	font-size: 1.6rem;

	@include media-breakpoint-up(xl) {
      font-size: 1.9rem;
    }
}
</style>
