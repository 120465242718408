const config = {
	services: {
		rext: {
			url: process.server ? 'http://back:3000' : window.location.origin + '/api',
			imagesUrl: '/api/images/',
			filesUrl: '/api/files/'
		},
		recaptcha: {
			apiKey: process.server ? '' : window.__CONFIG__.recaptchaKey
		}
	},
	mediaQueries: {
		xxl: '(min-width: 1600px)',
		xl: '(min-width: 1200px)',
		lg: '(min-width: 992px)',
		landscape: '(orientation: landscape) and (min-width: 768px) and (max-width: 915px)',
		md: '(min-width: 768px)',
		sm: '(min-width: 576px)',
		base: '(min-width: 300px)'
	}
}

export {
	config
}
