
<template>
	<a
		:href="path"
		:class="[
			'link',
			`link-${type}`,
			underline ? 'underline' : 'no-underline'
		]"
		@click.prevent="handleClick()"
		:target="target"
        :title="title"
	>
		<slot />
		<base-svg name="link_info_icon"/>
	</a>
</template>

<script>

import { NavigationMixin } from '~/cms/front/mixins/NavigationMixin'

export default {
	mixins: [NavigationMixin],
	props: {
		type: {
			type: String,
			default: 'primary'
		},
		underline: {
			type: Boolean,
			default: false
		}
	}
}
</script>
