import { rextServiceExtend } from '~/career/front/core/services/RextService'
import { JobOffer } from '~/career/front/core/entities/JobOffer'

const JobOfferPage = async () => (await import('./core/pages/JobOfferPage')).JobOfferPage
const JobOffersList = () => import('./sections/JobOffersList')

class CareerModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
		app.addPage('offer', JobOfferPage)
	}

	readyToAddEntities (app) {
		app.addEntity('job-offer', JobOffer)
	}

	async beforeInitReactive (app) {
		app.addSection('job_offers_list', JobOffersList)
	}
}

export { CareerModule }
