class JobOffer {
	static async fetch ({ take, skip, where, order } = {}) {
		const service = this.app.getService('rext')
		const data = await service.getJobOffers({ take, skip, where, order })

		if (data.error) return []
		return data.map(jobOffer => JobOffer.create(jobOffer))
	}

	static async fetchOne (alias) {
		const service = this.app.getService('rext')
		const data = await service.getJobOffer(alias)

		if (data.error) return null

		const JobOffer = this.app.getEntity('job-offer')
		return JobOffer.create(data)
	}

	static create (data = {}) {
		const JobOffer = this.app.getEntity('job-offer')
		const instance = new JobOffer()

		Object.entries(data).forEach(([property, value]) => {
			if (property in instance) instance[property] = value
		})

		return instance
	}

	id
	page
    meta
	name
	alias
	thumbnail
	tag
	dateCreated
	data

	get app () {
		return this.constructor.app
	}
}

export { JobOffer }
