import { DefaultLayout } from './core/layouts/DefaultLayout'
import { rextServiceExtend } from './core/services/RextService'
import registerGlobalComponents from './components/_globals'
import { BlankLayout } from './core/layouts/BlankLayout'
import { SimplifiedLayout } from './core/layouts/SimplifiedLayout'
const ContactPage = async () => (await import('./core/pages/ContactPage')).ContactPage
const PolicyPrivacy = async () => (await import('./core/pages/PolicyPrivacy')).PolicyPrivacy
const ProjectsPage = async () => (await import('./core/pages/ProjectsPage')).ProjectsPage
const DefaultPage = async () => (await import('./core/pages/DefaultPage')).DefaultPage
const DownloadPage = async () => (await import('./core/pages/DownloadPage')).DownloadPage
const NotFoundPage = async () => (await import('./core/pages/NotFoundPage')).NotFoundPage
const BussToBussPage = async () => (await import('./core/pages/BussToBussPage')).BussToBussPage
const Slider = () => import('~/website/front/sections/Slider/')
const PhotoSection = () => import('~/website/front/sections/PhotoSection/')
const CategoriesSection = () => import('~/website/front/sections/CategoriesSection/')
const CtaSection = () => import('~/website/front/sections/CtaSection/')
const CtaContactSection = () => import('~/website/front/sections/CtaContactSection/')
const ProductsSection = () => import('~/website/front/sections/ProductsSection/')
const DividerSection = () => import('~/website/front/sections/DividerSection/')
const IconsSection = () => import('~/website/front/sections/IconsSection/')
const AccordionSection = () => import('~/website/front/sections/AccordionSection/')
const HeroSection = () => import('~/website/front/sections/HeroSection/')
const CategoryHeroSection = () => import('~/website/front/sections/CategoryHeroSection/')
const CategoryProductSection = () => import('~/website/front/sections/CategoryProductSection/')
const TextContent = () => import('~/website/front/sections/TextContent/')
const OpinionSection = () => import('~/website/front/sections/OpinionSection/')
const GallerySection = () => import('~/website/front/sections/GallerySection/')
const RealizationsSection = () => import('~/website/front/sections/RealizationsSection/')
const TextImageSection = () => import('~/website/front/sections/TextImageSection/')
const Subtitle = () => import('~/website/front/sections/Subtitle/')
const Buttons = () => import('~/website/front/sections/Buttons/')
const CtaBussToBussSection = () => import('~/website/front/sections/CtaBussToBussSection/')
const WhyUsSection = () => import('~/website/front/sections/WhyUsSection/')
const CtaProduceSection = () => import('~/website/front/sections/CtaProduceSection/')
const OurProductsSection = () => import('~/website/front/sections/OurProductsSection/')
const SquadSection = () => import('~/website/front/sections/SquadSection/')
const CtaBenefitSection = () => import('~/website/front/sections/CtaBenefitSection/')
const LogosSection = () => import('~/website/front/sections/LogosSection/')
const SpecialistSection = () => import('~/website/front/sections/SpecialistSection/')

class WebsiteModule {
	sections = []

	constructor (app) {
		this.app = app
		this.app.sections = {}
		this.app.addSection = (alias, component) => {
			this.app.sections[alias] = component
		}
	}

	afterRouterCreated (app) {
		app.router.afterEach(async (to, from) => {
			if (process.client && from.meta.alias) window.scrollTo(0, 0)
		})
	}

	readyToAddPages (app) {
		app.addPage('not-found', NotFoundPage)
		app.addPage('default', DefaultPage)
		app.addPage('contact', ContactPage)
		app.addPage('eu-projects', ProjectsPage)
		app.addPage('download', DownloadPage)
		app.addPage('privacy-policy', PolicyPrivacy)
		app.addPage('buss-to-buss', BussToBussPage)
	}

	readyToAddLayouts (app) {
		app.addLayout(DefaultLayout)
		app.addLayout(BlankLayout)
		app.addLayout(SimplifiedLayout)
	}

	beforeInitReactive (app) {
		registerGlobalComponents(app)
		app.addSection('slider', Slider)
		app.addSection('subtitle', Subtitle)
		app.addSection('buttons', Buttons)
		app.addSection('text_content', TextContent)
		app.addSection('photo_section', PhotoSection)
		app.addSection('divider_section', DividerSection)
		app.addSection('contact_form', CtaSection)
		app.addSection('accordion_section', AccordionSection)
		app.addSection('icons_section', IconsSection)
		app.addSection('products_section', ProductsSection)
		app.addSection('categories_section', CategoriesSection)
		app.addSection('hero_section', HeroSection)
		app.addSection('category_hero_section', CategoryHeroSection)
		app.addSection('category_product_section', CategoryProductSection)
		app.addSection('opinion_section', OpinionSection)
		app.addSection('realizations_section', RealizationsSection)
		app.addSection('gallery_section', GallerySection)
		app.addSection('text_image_section', TextImageSection)
		app.addSection('contact_form', CtaContactSection)
		app.addSection('cta_buss_section', CtaBussToBussSection)
		app.addSection('why_us_section', WhyUsSection)
		app.addSection('cta_produce_section', CtaProduceSection)
		app.addSection('our_products_section', OurProductsSection)
		app.addSection('squad_section', SquadSection)
		app.addSection('cta_benefit_section', CtaBenefitSection)
		app.addSection('logos_section', LogosSection)
		app.addSection('specialist_section', SpecialistSection)
	}

	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}
}
export { WebsiteModule }
