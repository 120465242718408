<template>
	<div class="heading-wrapper" :class="{'white-heading': white}">
		<cms-text
			v-if="heading"
			:props="{ variant: 'heading', tag: tag, color: white ? 'white' : null }"
			:value="heading"
			:layout="layout"
		/>
		<cms-text
			v-if="subheading"
			class="subheading"
			:props="{ variant: 'subheading', color: white ? 'white' : null }"
			:value="subheading"
			:layout="layout"
		/>
	</div>
</template>
<script>
export default {
	props: {
		heading: String,
		subheading: String,
		layout: Boolean,
		white: {
			type: Boolean,
			default: false
		},
		tag: {
			type: String,
			default: 'h2'
		}
	}
}
</script>
<style lang="scss" scoped>
	.heading-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		@include media-breakpoint-up(lg) {
			text-align: left;
		}
		margin-bottom: 4rem;
		::v-deep .subheading {
			position: relative;
			display: flex;
			align-items: center;
			&::before, &::after {
				content: '';
				width: 30px;
				flex: 1;
				border-bottom: 1px solid $secondary;
				@include media-breakpoint-up(xl) {
					width: 90px;
				}
			}
            &::before {
                margin-inline-end: 10px;
            }
            &::after {
                margin-inline-start: 10px;
            }
		}
		&.white-heading {
			::v-deep .subheading {
				&::before, &::after {
					border-bottom: 1px solid $white;
				}
			}
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 8rem;
		}
	}
</style>
