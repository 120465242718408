class Product {
	static async fetch ({ take, skip, where, order }) {
		const service = this.app.getService('rext')
		const data = await service.getProducts({ take, skip, where, order })

		if (data.error) return []

		const Product = this.app.getEntity('product')

		return {
			products: data.map(product => Product.create(product))
		}
	}

	static async fetchOne (alias) {
		const service = this.app.getService('rext')
		const data = await service.getProduct(alias)

		if (data.error) return null

		const Product = this.app.getEntity('product')
		return Product.create(data)
	}

	static create (data = {}) {
		const Product = this.app.getEntity('product')
		const instance = new Product()

		Object.entries(data).forEach(([property, value]) => {
			if (property in instance) instance[property] = value
		})

		return instance
	}

	id
	page
	name
	alias
	description
	thumbnail
	thumbnailHover
	brand
	type
	sectionType
	tags
	productData

	get app () {
		return this.constructor.app
	}
}

export { Product }
