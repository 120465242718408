import { getQuery } from 'utils/query'

export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async getProducts ({ take, skip, where, order }) {
			const query = getQuery({
				relations: [],
				take,
				skip,
				where,
				order
			})
			const { data, status } = await this.http.get(
				`/product${query}`
			)

			if (status !== 200) return { error: true }
			return data
		}

		async getProduct (alias) {
			const { data, status } = await this.http.get(
				`/site/product/${alias}`
			)

			if (status !== 200) return { error: true }
			return data
		}
	}
}
