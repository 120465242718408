<template>
	<div v-if="modal">
		<div class="modal">
			<button-close @close="close" > {{modal.data.name[$app.language]}} </button-close>
			<div class="content">
				<component :is="modal.component" :modal="modal"/>
			</div>
		</div>
		<div class="overlay" @click.prevent="close"></div>
	</div>
</template>

<script>
import ButtonClose from '~/website/front/components/molecules/ButtonClose'
export default {
	components: {
		ButtonClose
	},
	computed: {
		modal () {
			return this.$app.modal
		}
	},
	methods: {
		close () {
			this.modal.close()
		}
	}
}
</script>

<style lang="scss" scoped>
.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
    z-index: $level3;
	width: 100%;
	height: 95vh;
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: $border-radius;
	-ms-overflow-style: none;
	scrollbar-width: none;
	@include media-breakpoint-up(md) {
		width: 85%;
		height: 90vh;
	}
	@include media-breakpoint-up(lg) {
		overflow: hidden;
		width: 95%;
		height: min-content;
	}
	@include media-breakpoint-up(xl) {
		overflow: hidden;
		width: 90%;
		max-height: 90vh;
	}
	@include media-breakpoint-up(xxl) {
		width: 75%;
		max-height: min-content;
	}

	&.big-modal {
		width: 50vw;
		height: 90vh;
	}
	&::-webkit-scrollbar {
		display: none;
	}

	.content {
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 0 20px;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
		@include media-breakpoint-up(lg) {
			padding: 1rem 2rem;
		}
		@include media-breakpoint-up(xxl) {
			overflow-y: initial;
			padding: 2rem 4rem;

		}
	}
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: $level2;
	background-color: rgba($black, 0.35);
	backdrop-filter: blur(4px);
}

</style>
