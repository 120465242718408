<template>
	<div :class="[`child child-${index + 1}`, isActive && 'active' ]" >
		<base-link class="dropdown-group-link" :to="element.link">
			<base-svg class="child-icon" :name="element.icon"/>
			<base-font class="dropdown-group-name" variant="paragraph" color="primary">
				{{element.name}}
			</base-font>
		</base-link>
	</div>
</template>
<script>
export default {
	props: {
		element: Object,
		index: Number
	},
	computed: {
		isActive () {
			if (this.$app.page.product) {
				return this.element.link.includes(this.$app.page.product.type)
			} else if (this.element.link === 'career' && this.$route.meta.alias === 'offer') {
				return true
			} else {
				return this.element.link.includes(this.$route.meta.alias)
			}
		}
	}
}
</script>
<style lang="scss" scoped>

	.child {
		@include media-breakpoint-up(xl) {
			position: relative;
			align-self: baseline;
			display: flex;
			flex-direction: column;
            height: 100%;
            width: 100%;
		}
		::v-deep &-icon {
			height: 60px;
			margin-bottom: 15px;
			path:not(.no-bg) {
				fill: $secondary;
			}
			@include media-breakpoint-up(xl) {
				height: 54px;
				width: auto;
				margin-bottom: 20px;
			}
		}
		&.active {
			::v-deep .dropdown-group-name {
				color: $secondary;
			}
		}
	}

.nav {
	.dropdown-group-link {
		margin-bottom: 0;
		text-decoration: none;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		::v-deep .svg-icon {
			fill: $secondary;
		}
		@include media-breakpoint-up(xl) {
            padding: 4rem 0 3rem 0;
			&:hover {
				::v-deep .dropdown-group-name {
					color: $secondary-300;
				}
				::v-deep .svg-icon {
					fill: $secondary-300;
				}
			}
		}
		.svg-icon {
			transition: .3s ease-in;
		}
		::v-deep .dropdown-group-name {
			font-size: 1.4rem;
			line-height: 1.7rem;
			font-weight: 500;
			text-align: center;
			transition: .3s ease-in;
			@include media-breakpoint-up(xl) {
				max-width: 100px;
				height: 34px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
</style>
<style lang="scss">
#app.de .dropdown-group-name {
	max-width: none;
}
#app.de .nav-item-dropdown .inner-wrapper{
	@media (max-width: 576px) {
		column-gap: clamp(30px,10vw,70px);
		padding: 4rem 2rem;
	}
}
</style>
