<template>
	<div class="navbar-wrapper" :class="{'moved': scrolled}">
		<base-container>
			<div class="navbar-inner-wrapper">
				<menu-logo-wrapper
					:activeMobileNav="activeMobileNav"
					@menuButtonClick="toggleMobileBtnClick"
				/>
				<div
					class="nav-wrapper"
					:class="{active: activeMobileNav}"
				>
					<div class="navbar-top">
						<div class="top-wrapper">
							<base-socials />
							<button @click.stop="toggleMobileBtnClick" class="navbar-btn-close">
								<base-svg name="close"/>
							</button>
						</div>
						<base-svg name="logo_color" class="mobile-logo"/>
					</div>
					<nav class="nav">
						<ul class="nav-list">
							<menu-item
								v-for="item in menuItems.filter(item => item.show_in_menu)"
								:key="item.name"
								:item="item"
							/>
						<base-translations />
						</ul>
					</nav>
				</div>
			</div>
		</base-container>
	</div>
</template>
<script>
import MenuLogoWrapper from './components/MenuLogoWrapper'
import MenuItem from './components/MenuItem'
import BaseSocials from '../../molecules/socials/BaseSocials/BaseSocials.vue'
import BaseTranslations from '../../molecules/BaseTranslations/BaseTranslations.vue'

export default {
	data: () => ({
		scrollPosition: 0,
		scrolled: false,
		activeMobileNav: false,
		isHomepage: false
	}),
	components: {
		MenuLogoWrapper,
		MenuItem,
		BaseSocials,
		BaseTranslations
	},
	computed: {
		phoneNumber () {
			return this.$app.settings.general.contact.phoneNumber
		}
	},
	props: {
		menuItems: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	methods: {
		toggleMobileBtnClick () {
			this.$app.vue.$el.style = !this.activeMobileNav ? 'height: 100vh;' : 'height: auto;'
			this.activeMobileNav = !this.activeMobileNav
		},
		closeMobileMenu () {
			this.activeMobileNav = false
		},
		toggleNavbar () {
			if (window.scrollY > 50) this.scrolled = true
			else this.scrolled = false
		}
	},
	mounted () {
		if (this.$route.meta.alias === 'homepage') {
			window.addEventListener('scroll', this.toggleNavbar)
		} else this.scrolled = true
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.toggleNavbar)
	},
	watch: {
		$route () {
			this.closeMobileMenu()
			if (this.$route.meta.alias === 'homepage') {
				this.scrolled = false
				window.addEventListener('scroll', this.toggleNavbar)
			} else {
				this.scrolled = true
				window.removeEventListener('scroll', this.toggleNavbar)
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.navbar-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $level2;
	width: 100%;
	@include media-breakpoint-up(xl) {
		&.moved {
			.nav-wrapper {
				margin-top: -26px;
			}
			.navbar-inner-wrapper::before {
				top: 0;
				transform: none;
				background: $secondary-500;
			}
		}
	}
	.navbar-inner-wrapper {
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -50vw;
			width: 150vw;
			height: 55px;
			z-index: -2;
			background: $secondary-500;
			transition: .2s ease-in;

			@include media-breakpoint-up(xl) {
				height: 79px;
				background: rgba($secondary-500, .5);
				top: 50%;
				transform: translateY(-50%);
			}
		}

	}
	.navbar-top {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2rem 3rem 4.5rem 2rem;
		background: $gray-300;
		border-bottom: 11px solid $secondary;

		.top-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(50% + 30px);
			margin-bottom: 35px;
			margin-left: auto;
		}
		.mobile-logo {
			max-width: 232px;
			height: auto;
		}
		@include media-breakpoint-up(xl) {
			display: none;
		}
		.navbar-btn-close {
			display: inline-block;
			margin: 0;
			cursor: pointer;
			border: 0;
			outline: none;
			background-color: transparent;
			& svg {
				width: 20px;
				path {
					fill: $black;
				}
			}
		}
	}
}
.nav-wrapper {
	width: 100%;
	transform: translateX(100%);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	transition: transform .4s ease-in;
	background-color: $white;
	z-index: $level2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include media-breakpoint-up(xl){
		transition: .2s ease-in;
		transform: none;
		position: static;
		background: none;
		flex-direction: row-reverse;
	}

	&.active {
		transform: translateX(0);
	}
}
.nav {
	padding-bottom: 6rem;
	overflow-y: scroll;
	height: calc(100vh - 186px);
	@include media-breakpoint-up(xl) {
		overflow-y: inherit;
		display: flex;
		height: auto;
		width: 100%;
		padding-bottom: 0;
	}
	&-list {
		list-style: none;
		padding: 0;
		margin: 0;
		@include media-breakpoint-up(xl) {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
		}
	}
}
</style>
