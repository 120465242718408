<template>
	<section class="base-form-wrapper contact-section" :class="{'b2b': isBussToBussPage}">
		<base-container>
			<div class="inner-wrapper">
				<figure class="img-wrapper">
					<cms-image
						class="img"
						value="contact-form.bg_picture"
						position="top"
						:base="{width: 705, height: 400}"
                        :sm="{width: 900, height: 350}"
                        :md="{width: 850, height: 400}"
                        :lg="{width: 705, height: 1042}"
                        :xl="{width: 705, height: isBussToBussPage ? 824 : 1150}"
						layout
					/>
				</figure>
				<div class="wrapper-form-side">
					<base-heading heading="contact-form.title" subheading="contact-form.under_title_text" :layout="true" v-animate="`opacity`"/>
					<base-blur :blur="!!status">
						<form class="form" v-if="form">
							<div class="form-inner-wrapper" v-animate="`opacity`">
								<input-template wider :input="form.inputs.name" />
								<input-template wider :input="form.inputs.surname" />
							</div>
							<div class="form-inner-wrapper" v-animate="`opacity`">
								<input-template wider :input="form.inputs.phoneNumber" />
								<input-template wider :input="form.inputs.email" />
							</div>
							<input-template :input="form.inputs.content" v-animate="`opacity`" />
							<input-template :input="form.inputs.interestedIn" v-animate="`opacity`"/>
							<input-template :input="form.inputs.policy" v-animate="`opacity`"/>

							<button-submit
								@click="submit"
								:loading="loading"
								type="secondary"
								class="form-btn"
								icon="send"
                                v-animate="`opacity`"
							>
								<base-font clear>
									{{langs.button}}
								</base-font>
							</button-submit>
						</form>
						<template #status>
							<div class="status" >
								<base-font class="status-text" weight="bold" size="md" color="primary">
									{{statusTranslation[status] || statusTranslation.error}}
								</base-font>
							</div>
						</template>
					</base-blur>
					<div class="numbers" v-animate="`opacity`">
						<div class="number-wrapper">
							<base-link class="number-link" :url="`tel:${generalData.phoneNumber}`" title="Zadzwoń do nas">
								<base-svg class="svg-phone" name="phone"/>
								<base-font class="number-text" variant="number">
									{{generalData.phoneNumber}}
								</base-font>
							</base-link>
						</div>
						<div class="number-wrapper">
							<base-link class="number-link" :url="`tel:${generalData.additionalPhoneNumber}`" title="Numer kontaktowy do firmy">
								<base-svg class="svg-phone" name="phone"/>
								<base-font class="number-text" variant="number">
									{{generalData.additionalPhoneNumber}}
								</base-font>
							</base-link>
						</div>
					</div>
					<base-font class="desc" tag="p" color="primary" v-if="isBussToBussPage">Podanie danych jest dobrowolne, jednakże niezbędne w celu kontaktu z Państwem.</base-font>
				</div>
			</div>
		</base-container>
	</section>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'

import { Form } from '@f/core/forms/Form'
import { InputTemplate, InputText, InputEmail, Textarea, InputCheckbox } from '@f/core/forms/inputs'
import { required, email } from 'utils/inputs/validation-front'
import BaseHeading from '../../components/molecules/headings/BaseHeading/BaseHeading.vue'
export default {
	data () {
		return {
			status: '',
			form: false,
			loading: false
		}
	},
	computed: {
		statusTranslation () {
			return this.langs.status
		},
		langs () {
			return this.$app.translator.get('forms.contactForm')
		},
		generalData () {
			return this.$app.settings.general.contact
		},
		isBussToBussPage () {
			if (this.$route.path === '/b2b') {
				return true
			}
			return false
		}
	},
	components: {
		InputTemplate,
		ButtonSubmit,
		BaseHeading
	},
	async mounted () {
		await this.$app.translator.prefetch('errors')
		await this.$app.translator.prefetch('forms.contactForm')
		this.form = new Form()
		this.form.addInput(InputText.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputName'),
			attribute: 'name',
			name: 'name',
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputSurname'),
			attribute: 'surname',
			name: 'surname',
			validation: [required()]
		}))
		this.form.addInput(InputText.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputPhoneNumber'),
			attribute: 'phoneNumber',
			name: 'phoneNumber',
			mask: '+## ### ### ###',
			validation: [required()]
		}))
		this.form.addInput(InputEmail.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputEmail'),
			attribute: 'email',
			name: 'email',
			validation: [email()]
		}))
		this.form.addInput(Textarea.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputAdditionalInfo'),
			attribute: 'content',
			name: 'content',
			validation: [required()]
		}))
		this.form.addInput(Textarea.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputInterestedIn'),
			attribute: 'interestedIn',
			name: 'interestedIn',
			validation: [required()]
		}))
		this.form.addInput(InputCheckbox.create({
			translations: () => this.$app.translator.get('forms.contactForm.inputPolicy'),
			attribute: 'policy',
			name: 'policy',
			validation: [required()]
		}))
	},
	methods: {
		async submit () {
			if (!await this.form.validate()) return false
			this.loading = true
			const values = this.form.getValues()
			const status = await this.$app.getService('rext').sendContactFormMessage(
				values
			)
			this.loading = false
			this.status = status

			if (status === 201) this.$app._emit('contact_form:message:sent')
		}
	}
}
</script>
<style lang="scss" scoped>
.base-form-wrapper {
	background-color: $white;
	overflow: hidden;
	.inner-wrapper {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 420px 510px;
			margin-bottom: -10px;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 120px;
			grid-template-columns: 425px 640px;
			gap: 40px;
		}
		@include media-breakpoint-up(xxl) {
			gap: 150px;
			grid-template-columns: 425px auto;
		}
	}
}
.img-wrapper {
	width: 100vw;
	position: relative;
	left: 50%;
	transform: translateX(-100%);
	margin: 0 0 4rem 50vw;
	@include media-breakpoint-up(lg) {
		width: 515px;
		position: relative;
		left: 0;
		margin: 0;
		transform: translateX(-26%);
		margin-bottom: 0;
	}
	@include media-breakpoint-up(xl) {
		width: auto;
		transform: none;
		margin: 0;
	}
	@media (min-width: 2500px) {
		width: 400px;
	}
	.img {
		@include media-breakpoint-up(xl) {
			display: inline-block;
		}

		::v-deep img {
			width: 100%;
			height: 180px;
			object-fit: cover;
			@include media-breakpoint-up(md) {
				height: 300px;
			}
			@include media-breakpoint-up(lg) {
				width: auto;
				height: auto;
			}

			@include media-breakpoint-up(lg){
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
			}
			@media (min-width: 2500px) {
				width: 1050px;
				height: 1150px;
			}

			@media screen and (min-width: 600px) {
				@media screen and (max-height: 380px) {
					object-position: bottom;
				}
			}
		}
        ::v-deep picture {
            display: block;
        }
	}
}
.wrapper-form-side {
	padding-bottom: 4rem;
	@include media-breakpoint-up(lg) {
		padding: 8rem 0;
	}

	::v-deep label {
		color: $primary;
	}
	::v-deep .form-btn {
		margin-top: 2rem;
		@include media-breakpoint-up(xl) {
			margin-top: 0;
			justify-content: center;
			.btn.button {
				margin-top: 0;
			}
		}
	}
	.form-inner-wrapper {
		@include media-breakpoint-up(md) {
			display: flex;
		}
	}
}
	.numbers {
		position: relative;
		margin-top: 8rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 2rem;
		@include media-breakpoint-up(xl) {
			flex-direction: row;
			position: relative;
			width: 110%;
			left: -20px;
			margin-top: 12rem;
			.number-link {
				::v-deep .number-text {
					@include transition;
				}
			}
			.number-link:hover {
				::v-deep .number-text {
					color: $secondary;
				}
			}
		}
		&::before {
			content: '';
			position: absolute;
			top: -40px;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: rgba(#146C9D, 0.12);
			@include media-breakpoint-up(xl) {
				top: -60px;
			}
		}
		.number-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;

		}
		.svg-phone {
			width: 35px;
			height: 35px;
			margin-right: 2rem;
			& ::v-deep path {
				fill: $secondary;
			}
		}
	}

	.b2b {
		.heading-wrapper, .numbers {
			display: none;
		}
	}
	::v-deep .desc {
			margin-top: 2rem;
			text-align: center;
	}
</style>
