<template>
	<base-link @click="download(to)" type="secondary" class="footer-link link" >
		{{name}}
	</base-link>
</template>
<script>
import path from 'path'

function bufferToBase64 (buffer) {
	return new Promise((resolve, reject) => {
		const blob = new Blob([buffer], { type: 'application/octet-stream' })
		const reader = new FileReader()
		reader.onloadend = () => resolve(reader.result)
		reader.onerror = reject
		reader.readAsDataURL(blob)
	})
}

export default {
	props: {
		name: String,
		to: Object
	},
	methods: {
		async download (to) {
			const service = this.$app.getService('rext')
			const bufferData = await service.getFile(to.path)

			const fileUrl = await bufferToBase64(bufferData)

			const fileLink = document.createElement('a')
			const fileName = path.basename(decodeURI(to.path))

			fileLink.href = fileUrl
			fileLink.setAttribute('download', fileName)
			document.body.appendChild(fileLink)

			fileLink.click()
		}
	}
}
</script>
<style lang="scss" scoped>
.footer-link.link {
	display: block;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 1.2rem;
	color: $gray;
	margin-bottom: 1.8rem;
	font-weight: 500;
	line-height: 1.6rem;
	@include media-breakpoint-up(xl) {
		font-size: 1.4rem;
		@include transition;
		&:hover {
			color: $secondary;
		}
	}
}
</style>
