<template>
	<base-container>
		<div class="user-profile-wrapper">
			<div>
				<div v-if="checkView">
					<client-navigation/>
				</div>
				<div v-if="client">
					<transition
						name="fade"
						mode="out-in"
					>
						<component key="user-view" :is="view.component" />
					</transition>
					<base-button to="user-profile" :params="{view: 'menu'}" v-if="!checkView" key="back-button" type="white">{{langs.main.backButton}}</base-button>
				</div>
			</div>
		</div>
	</base-container>
</template>
<script>
import ClientNavigation from '~/shop/front/components/ClientNavigation'

export default {
	data () {
		return {
			ready: false
		}
	},
	components: {
		ClientNavigation
	},
	computed: {
		view () {
			return this.$app.page.view
		},
		langs () {
			return this.$app.translator.get('client-profile')
		},
		page () {
			return this.$app.page
		}
	},
	watch: {
		'page.ready' () {
			this.ready = true
		}
	}
}
</script>
<style lang="scss" scoped>
	.user-profile-wrapper {
		padding-block: 4rem;
		@include media-breakpoint-up(lg) {
			padding-block: 8rem;
			min-height: 590px;
		}
		::v-deep .btn-white {
			width: 50%;
			justify-content: center;
			margin: 5rem 0 0 0;
		}
	}
	.fade-enter-active {
	transition: all .3s ease-in;
	}
	.fade-leave-active {
	transition: all .3s ease-out;
	}
	.fade-enter, .fade-leave-to {
		@include media-breakpoint-up(lg) {
			opacity: 0;
			transform: translateX(50px);
		}
	}
	.fade-leave-to {
		position: absolute;
	}
	* {
		overflow: hidden;
	}
</style>
