<template>
	<div class="cookie-wrapper" v-if="show">
		<cms-text
			:props="{color:'primary', weight:'bold'}"
			value="cookie.title"
			layout
		/>
		<cms-text
			:props="{tag:'div', color:'primary'}"
			value="cookie.content"
			layout
		/>
		<base-link class="cookie-link" type="black" :url="`${$app.layout.value.cookie.link}`" title="Polityka prywatności oraz cookies..">
			<cms-text :props="{clear: true}" value="cookie.link_content" layout/>
		</base-link>
		<base-svg name="cookie"/>
		<base-button
			@click="acceptCookie"
			type="secondary"
			class="btn-cookie"
		>
			<cms-text
				:props="{clear: true}"
				value="cookie.btn_text"
				layout
			/>
		</base-button>
	</div>
</template>
<script>
import cookies from 'js-cookie'
import BaseLink from '../../atoms/BaseLink/BaseLink.vue'

export default {
	data () {
		return {
			show: false
		}
	},
	components: {
		BaseLink
	},
	mounted () {
		if (!cookies.get('cookie')) this.show = true
	},
	methods: {
		acceptCookie () {
			cookies.set('cookie', true, { expires: 365 })
			this.show = false
		}
	}
}
</script>
<style lang="scss" scoped>
.cookie-wrapper {
	position: fixed;
	left: 0;
	bottom: 0;
	background-color: $white;
	width: 100%;
	max-width: 380px;
	padding: 2rem 1rem;
	border-top-left-radius: $border-radius;
	box-shadow:  0 3px 15px rgba(#000000, .6);
	z-index: $level4;
	overflow: hidden;
	& > p:first-child {
		margin-bottom: 2rem;
	}
	@include media-breakpoint-up(sm) {
		padding: 3rem;
	}
	&::v-deep .cookie-link {
		margin-top: 1rem;
		display: inline-block;
	}
	.svg-icon {
		width: 150px;
		height: 150px;
		position: absolute;
		top: -2rem;
		left: -3rem;
		transform: rotate(10deg);
		opacity: .2;
		&::v-deep path {
			stroke: none;
			fill: $secondary;
		}
	}
	&::v-deep .btn-cookie {
		margin-block: 3rem 1rem;
		margin-left: auto;

	}
}
</style>
