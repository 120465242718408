import Cookies from 'js-cookie'
import { ReactiveClass } from '@f/core/ReactiveClass'

class WebsiteLanguageManager extends ReactiveClass {
	static create (app) {
		const instance = new WebsiteLanguageManager(app)
		return instance
	}

	constructor (app) {
		super()
		this.app = app
	}

	initialLanguage

	get defaultCmsLanguage () {
		return this.app.settings.general.languages.defaultCmsLanguage
	}

	get languageSelect () {
		return document.querySelector('.goog-te-combo') || null
	}

	setInitialLanguage () {
		try {
			this.initialLanguage = Cookies.get('googtrans').split('/')[2]
		} catch (e) {
			this.initialLanguage = this.app.language
		}
		this._toggleAppLanguageClass(this.initialLanguage)
	}

	initTranslator () {
		if (window.google && window.google.translate) return

		this.app.on('language:change', languageAlias => this.changeLanguage(languageAlias))

		return new Promise(resolve => {
			window.googleTranslateElementInit = () => new window.google.translate.TranslateElement({ pageLanguage: 'pl' }, 'app')

			const script = document.createElement('script')
			document.body.appendChild(script)
			script.onload = resolve
			script.defer = true
			script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
		})
	}

	changeLanguage (languageAlias) {
		if (languageAlias === this.defaultCmsLanguage) {
			this.showOriginalContent()
		} else {
			const event = new Event('change')
			this.languageSelect.value = languageAlias
			this.languageSelect.dispatchEvent(event)
		}
		this._toggleAppLanguageClass(languageAlias)
	}

	showOriginalContent () {
		const iframe = document.getElementsByClassName('goog-te-banner-frame')[0]
		if (!iframe) return

		const innerDoc = iframe.contentDocument || iframe.contentWindow.document
		const restoreEl = innerDoc.getElementsByTagName('button')

		for (let i = 0; i < restoreEl.length; i++) {
			if (restoreEl[i].id.indexOf('restore') >= 0) {
				restoreEl[i].click()
				const closeEl = innerDoc.getElementsByClassName('goog-close-link')
				closeEl[0].click()
				return
			}
		}
	}

	_toggleAppLanguageClass (language) {
		this.app.vue.$el.className = ''
		this.app.vue.$el.classList.add(language)
	}
}

export { WebsiteLanguageManager }
