import { rextServiceExtend } from './core/services/RextService'
import { ArticleCategory } from '~/blog/front/core/entities/ArticleCategory/ArticleCategory'
const ArticlePage = async () => (await import('./core/pages/Article')).ArticlePage
const ArticleListPage = async () => (await import('./core/pages/ArticleList')).ArticleListPage
const ArticleListCategoryPage = async () => (await import('./core/pages/ArticleListCategory')).ArticleListCategoryPage
const NewestBlogPosts = () => import('./sections/NewestBlogPosts')
const ContentSection = () => import('./sections/ContentSection')

class BlogModule {
	readyToExtendServices (app) {
		app.extendService('rext', rextServiceExtend)
	}

	readyToAddPages (app) {
		app.addPage('article', ArticlePage)
		app.addPage('article-list', ArticleListPage)
		app.addPage('article-list-category', ArticleListCategoryPage)
	}

	readyToAddEntities (app) {
		app.addEntity('article-category', ArticleCategory)
	}

	beforeInitReactive (app) {
		app.addSection('newest_blog_posts', NewestBlogPosts)
		app.addSection('content_section', ContentSection)
	}
}

export { BlogModule }
