<template>
	<div class="default-layout-wrapper">
		<base-menu :menuItems="$app.layout.value.menu.items"/>
		<main class="content">
			<slot />
		</main>
		<base-cookie/>
<!--		<base-popup/>-->
		<cta-section v-if="getAlias !== 'contact'"/>
		<base-map :pins="$app.layout.value.mapData"/>
		<base-newsletter/>
		<base-footer/>
		<base-copyright class="default-layout-footer" link="https://www.rekinysukcesu.pl/"/>
		<!--<action-btn/>-->
		<social-media/>
		<b2b v-if="$app.page.alias !== 'buss-to-buss'"/>
	</div>
</template>

<script>
import BaseMenu from '~/website/front/components/organisms/BaseMenu'
import BaseCookie from '~/website/front/components/organisms/BaseCookie'
// import BasePopup from '~/website/front/components/organisms/BasePopup'
import BaseFooter from '~/website/front/components/sections/BaseFooter'
import BaseMap from '~/website/front/components/molecules/BaseMap'
import BaseNewsletter from '~/newsletter/front/sections/BaseNewsletter'
import BaseCopyright from '~/website/front/components/sections/BaseCopyright'
// import ActionBtn from '~/website/front/components/molecules/fixed/ActionBtn'
import SocialMedia from '~/website/front/components/molecules/fixed/SocialMedia'
import CtaSection from '~/website/front/sections/CtaSection'
import B2b from '~/website/front/components/molecules/fixed/B2b/B2b'

export default {
	components: {
		BaseMenu,
		BaseCopyright,
		BaseMap,
		BaseNewsletter,
		BaseFooter,
		BaseCookie,
		// BasePopup,
		// ActionBtn,
		SocialMedia,
		CtaSection,
		B2b
	},
	computed: {
		getAlias () {
			return this.$app.page.route.meta.alias
		}
	}
}
</script>
<style lang="scss" scoped>

.default-layout-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
.default-layout-footer {
	margin-top: auto;
}
</style>
