<template>
	<div class="fixed-social-media">
		<div class="icons-wrapper">
			<base-link class="social-link" :url="value.facebook" title="Zapraszamy na nasz fanpage na facebooku">
				<base-svg class="svg" name="facebook"/>
			</base-link>
			<base-link class="social-link" :url="value.linkedin" title="Sprawdź nasz firmowy profil na Linkedin!">
				<base-svg class="svg" name="linkedin"/>
			</base-link>
		</div>
	</div>
</template>
<script>
export default {
	computed: {
		value () {
			return this.$app.layout.value
		}
	}
}
</script>
<style lang="scss" scoped>
.fixed-social-media {
	display: none;
	@include media-breakpoint-up(xl) {

		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		align-items: center;
		position: fixed;
		right: 0;
		top: 11rem;

		z-index: 100;

		writing-mode: tb-rl;
		-webkit-text-orientation: mixed;
		text-orientation: mixed;
		transform: rotate(180deg);
		padding: 2.2rem 1.2rem;
		background-color: rgba($primary, 0.8);
		border-radius: 2px;
		::v-deep .svg {
			width: 2rem;
			height: 2rem;
			transform: rotate(180deg);
		}
		.social-link:hover {
			::v-deep .svg path {
				fill: $secondary;
			}
		}

		.link:first-of-type {
			margin-bottom: 1rem;
		}
	}
}
</style>
