export function rextServiceExtend (Service) {
	return class RextService extends Service {
		async sendNotFoundPage (path, fullPath) {
			const { data } = await this.http.post('/not-found', {
				path,
				fullPath
			})
			return data
		}

		async getFile (path) {
			if (!path) return { error: true }
			const response = await this.http.get(`/filemanager/files/${path}`, {
				responseType: 'arraybuffer'
			})

			if (response.status !== 200) return { error: true }

			return response.data
		}
	}
}
